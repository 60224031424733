<template>
  <div class="satisfied-container" @click.self="handleClick">
    <div class="background">
      <div class="title">
        <span class="left-icon"></span>
        <span class="title-text">感謝客郎的喜歡！</span>
        <span class="right-icon"></span>
      </div>
      <div class="content">
        <div class="text">
          我们为您提供了<em @click="goGenealogy">族地族谱查询</em>，
        </div>
        <div class="text">继续您的寻根之旅吧！</div>
        <div class="img">
          <img src="../../../assets/robot/插图.png" alt="" />
        </div>
        <div class="btn" @click="goGenealogy">前往获取</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    handleClick() {
      this.$emit("back", "satisfied");
    },
    goGenealogy() {
      this.$emit("goGenealogy");
    },
  },
};
</script>

<style lang="scss" scoped>
.satisfied-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);

  .background {
    width: 1049px;
    height: 818px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-55%, -50%);
    background: url("../../../assets/robot/满意背景框.png");
    padding-top: 80px;

    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      .left-icon {
        display: inline-block;
        width: 222px;
        height: 57px;
        background: url("../../../assets/robot/左边标题花纹.png");
      }
      .title-text {
        font-size: 30px;
        color: #715125;
      }
      .right-icon {
        display: inline-block;
        width: 222px;
        height: 57px;
        background: url("../../../assets/robot/左边标题花纹.png");
        transform: rotateY(180deg);
      }
    }

    .content {
      text-align: center;
      margin-top: 50px;

      em {
        color: #c87300;
        font-style: normal;
        cursor: pointer;
      }

      .text {
        font-size: 22px;
        line-height: 24px;
        color: #715125;
        font-family: "宋体";
        margin-top: 18px;
      }
      .img {
        margin-top: 30px;
        padding-right: 60px;
      }
      .btn {
        cursor: pointer;
        margin: 0 auto;
        margin-top: 10px;
        width: 199px;
        height: 55px;
        background: url("../../../assets/robot/按钮1.png");
        font-family: "宋体";
        color: #715125;
        font-size: 18px;
        padding-top: 15px;
      }
    }
  }
}
</style>