<template>
  <div class="genealogy-container" @click.self="onClose">
    <div class="background">
      <div class="left">祖地族譜</div>
      <div class="table">
        <div class="top">
          <div style="padding-right: 17px">
            <el-select
              v-model="placeAddress"
              placeholder="请选择"
              @change="onChangeAddress"
            >
              <el-option
                v-for="item in addressList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>

          <el-input
            v-model="title"
            placeholder="请输入中文题目"
            @keyup.native.enter="onSearch"
          >
            <i
              slot="suffix"
              @click="onSearch"
              style="
                color: #000;
                cursor: pointer;
                font-size: 18px;
                margin-right: 20px;
              "
              class="el-input__icon el-icon-search"
            ></i
          ></el-input>
        </div>
        <template v-if="total">
          <el-table
            :data="tableData"
            v-loading="loading"
            max-height="380"
            style="width: 100%"
            :row-style="{ height: '41px' }"
            :cell-style="{ padding: '0' }"
            :header-cell-style="{
              background: '#e3d7c7',
              height: '40px',
              color: '#8b867f',
            }"
          >
            <el-table-column prop="surname" label="姓氏" width="60px">
            </el-table-column>
            <el-table-column prop="sourceID" label="SourceID">
            </el-table-column>
            <el-table-column
              prop="title"
              label="题名"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="area"
              label="地区"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="version"
              label="版本"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column prop="extent" label="页数" width="60px">
            </el-table-column>
            <el-table-column
              prop="dTime"
              label="时间"
              :show-overflow-tooltip="true"
              width="60px"
            >
            </el-table-column>
            <el-table-column label="操作" width="180">
              <template slot-scope="scope">
                <el-button
                  @click="goToPreview(scope.row)"
                  type="text"
                  size="small"
                  >查看</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  @click="goToEBook(scope.row)"
                  >{{
                    scope.row.eBookForImgGenerated ? "电子书" : "原图"
                  }}</el-button
                >
                <el-button
                  type="text"
                  size="small"
                  v-show="scope.row.memberTreeFinished"
                  @click="goToTree(scope.row.sourceID)"
                  >查看世系树</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="pageIndex"
              :page-size="8"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </template>
        <div class="phone-book" v-if="!loading && total === 0">
          <div class="title">
            <span class="left-icon"></span>
            <span class="text">未查询到您的祖地族谱</span>
            <span class="right-icon"></span>
          </div>
          <div class="content">
            <div class="item" v-for="v in phoneNumberList" :key="v.id">
              <span class="icon"></span>
              <span
                >请联系村长/宗祠族长，联系电话：<i>{{
                  v.contractPhoneNumber
                }}</i></span
              >
            </div>
            <div class="item item1">
              <span class="icon"></span>
              <span
                >离别家乡岁月多，祖地人事未销磨。<em
                  >祝您开启愉快的寻根之旅！</em
                ></span
              >
            </div>
          </div>
          <div class="btn" @click="onClose">返回</div>
        </div>
      </div>
      <div class="right">地名辭書</div>
      <div class="right">方志</div>
    </div>
  </div>
</template>

<script>
import { getGenealogy } from "@/api/drillingIn.js";
import { GetPhoneNumber } from "@/api/user.js";
export default {
  props: {
    addressList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [],
      placeAddress: "all",
      title: "",
      pageIndex: 1,
      total: 0,
      loading: true,
      phoneNumberList: [],
    };
  },
  created() {
    this.getTableList();
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
    onSearch() {
      this.pageIndex = 1;
      this.getTableList();
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
      this.getTableList();
    },
    onChangeAddress(e) {
      this.pageIndex = 1;
      this.getTableList();
    },
    goToPreview(row) {
      let routeData = this.$router.resolve({
        path: "/genealogyInfo",
        query: {
          sourceID: row.sourceID,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goToEBook(row) {
      let routeData = this.$router.resolve({
        path: row.eBookForImgGenerated ? "/ebook2" : "/ebook",
        query: {
          sourceID: row.sourceID,
          id: row.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    goToTree(sourceID) {
      let routeData = this.$router.resolve({
        path: "/tree/" + sourceID,
      });
      window.open(routeData.href, "_blank");
    },
    async getTableList() {
      this.loading = true;
      let tempAddress = "";
      if (this.placeAddress === "all" && this.addressList.length) {
        tempAddress = this.addressList.slice(1).reduce((prev, next) => {
          return prev ? prev + "," + next.value : next.value;
        }, "");
      }
      try {
        const res = await getGenealogy({
          PageIndex: this.pageIndex,
          PageSize: 8,
          AcientPlaceAddress:
            this.placeAddress === "all" ? tempAddress : this.placeAddress,
          Title: this.title,
        });
        this.tableData = res.items;
        this.total = res.totalCount;
        if (this.total === 0) {
          const res2 = await GetPhoneNumber({
            area: this.placeAddress === "all" ? tempAddress : this.placeAddress,
          });
          this.phoneNumberList = res2.items;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.genealogy-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);

  .background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1431px;
    height: 680px;
    background: url("../../../assets/robot/族地族谱背景卷轴.png");
    padding: 53px 0 0 152px;
    display: flex;

    .left {
      width: 82px;
      height: 570px;
      background: url("../../../assets/robot/标签条宽.png");
      color: #fff;
      font-size: 18px;
      font-family: "宋体";
      writing-mode: vertical-rl;
      text-orientation: upright;
      letter-spacing: 3px;
      line-height: 82px;
      padding-top: 38px;
      font-weight: 600;
    }

    .table {
      width: 1085px;
      padding: 40px 25px 0;

      .top {
        margin-bottom: 20px;
        display: flex;

        ::v-deep .el-select {
          height: 40px;
          width: 165px;
        }

        ::v-deep .el-input__inner {
          background: #fbf9f3;
          border-color: transparent;
        }
        ::v-deep .el-input__inner:hover {
          border-color: transparent;
        }
        ::v-deep .el-input__inner:focus {
          border-color: transparent;
        }
      }
      .el-table {
        border: none;
        color: #8b867f;
      }
      ::v-deep .el-button--text {
        color: #8b867f;
      }
      .el-table,
      .el-table__expanded-cell {
        background: transparent;
      }
      ::v-deep .el-table tr {
        background: transparent;
      }
      ::v-deep .el-table--border::after,
      .el-table--group::after,
      .el-table::before {
        width: 0;
      }
      ::v-deep .el-table th.el-table__cell.is-leaf {
        border-bottom: none;
      }
      ::v-deep .el-table td.el-table__cell {
        border-bottom: 2px solid #d8cbbb;
      }
      ::v-deep
        .el-table--enable-row-hover
        .el-table__body
        tr:hover
        > td.el-table__cell {
        background: #e3d7c7;
      }
      .pagination {
        margin-top: 30px;
        text-align: center;

        ::v-deep .el-pager li {
          border-radius: 50%;
          height: 34px;
          width: 34px;
          line-height: 34px;
          text-align: center;
          margin: 0 5px;
        }
        ::v-deep .el-pagination .btn-prev {
          border-radius: 50%;
          height: 34px;
          width: 34px;
          line-height: 34px;
          text-align: center;
          margin: 0 5px;
          padding: 0;
        }
        ::v-deep .el-pagination .btn-next {
          border-radius: 50%;
          height: 34px;
          width: 34px;
          line-height: 34px;
          text-align: center;
          margin: 0 5px;
          padding: 0;
        }
        ::v-deep .el-pagination__jump {
          height: 34px;
          line-height: 34px;
          margin-left: 15px;
          font-size: 18px;
          font-family: "宋体";
          font-weight: 600;
        }
      }

      .phone-book {
        text-align: center;
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 55px;
          .left-icon,
          .right-icon {
            display: inline-block;
            width: 43px;
            height: 26px;
            background: url("../../../assets/robot/电话簿-标题纹样图标.png");
          }

          .text {
            font-size: 30px;
            color: #715125;
            font-family: "宋体";
            margin: 0 15px;
          }
        }
        .content {
          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5px;
            .icon {
              display: inline-block;
              width: 21px;
              height: 21px;
              background: url("../../../assets/robot/电话簿-小标题纹样图标.png");
              margin-right: 12px;
            }
            span {
              color: #795b30;
              font-size: 18px;

              em {
                font-style: normal;
                color: #cb7300;
              }

              i {
                font-style: normal;
              }
            }
          }
          .item1 {
            margin-top: 55px;
          }
        }

        .btn {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 199px;
          height: 50px;
          background: url("../../../assets/robot/按钮1.png");
          padding-bottom: 5px;
          color: #715125;
          margin: 0 auto;
          margin-top: 80px;
        }
      }
    }
    .right {
      width: 47px;
      height: 569px;
      background: url("../../../assets/robot/标签条窄.png");
      font-size: 18px;
      font-family: "宋体";
      writing-mode: vertical-rl;
      text-orientation: upright;
      letter-spacing: 3px;
      line-height: 47px;
      padding-top: 38px;
      font-weight: 600;
      color: #dea85f;
      margin-right: 3px;
    }
  }
}
</style>