<template>
  <div class="reward-container" @click.self="handleClick">
    <div class="background">
      <div class="title">恭喜您！</div>
      <div class="text">
        成为我们的<em>第{{ num }}个客郎</em>，我们将为您提
      </div>
      <div class="text">供免费的寻根之旅，祝您旅途愉快！</div>
      <div class="text" v-show="showCode">
        请联系客服：<span style="color: red">15280171486</span>
      </div>
      <div class="img">
        <img src="../../../assets/robot/中间图片.png" alt="" />
      </div>
      <div class="btn">
        <!-- <div class="left">族地寻根</div> -->
        <div class="right" @click="handleClick">返回</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num: {
      type: Number,
      required: true,
    },
    showCode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    handleClick() {
      this.$emit("back", "reward");
    },
  },
};
</script>

<style lang="scss" scoped>
.reward-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(6px);

  .background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-55%, -46%);
    width: 681px;
    height: 788px;
    padding-top: 70px;
    background: url("../../../assets/robot/奖励弹框背景.png");
    padding-right: 20px;

    .title {
      font-size: 30px;
      color: #715125;
      text-align: center;
      font-family: "宋体";
      margin-bottom: 35px;
    }
    .text {
      font-size: 22px;
      line-height: 24px;
      color: #715125;
      font-family: "宋体";
      margin-top: 15px;
      text-align: center;

      em {
        color: #c87300;
        font-style: normal;
      }
    }
    .img {
      text-align: center;
      margin-top: 25px;
    }
    .btn {
      display: flex;
      margin-top: 20px;
      padding: 0 100px;
      .left,
      .right {
        cursor: pointer;
        margin: 0 auto;
        margin-top: 10px;
        width: 199px;
        height: 55px;
        background: url("../../../assets/robot/按钮1.png");
        font-family: "宋体";
        color: #715125;
        font-size: 18px;
        padding-top: 15px;
        text-align: center;
      }
    }
  }
}
</style>