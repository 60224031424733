import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import route from '@/router'
const service = axios.create({
    // baseURL: '/',
    timeout: 15000,
    withCredentials: true
})

service.interceptors.request.use(config => {
    return config
}, error => {
    Promise.reject(error)
})


service.interceptors.response.use(response => {
    const { status, data } = response // 解构数据
    if (status === 200) {
        return data
    } else {
        return Promise.reject(new Error('操作失败！'))
    }
}, error => {

    if (error.response.status) {
        // token过期被动处理
        switch (error.response.status) {
            case 401:
                Message({
                    message: '登录过期，请重新登录',
                    duration: 2000,
                    forbidClick: true
                })
                // 退出登录
                store.dispatch('user/logout')
                route.push(`/login?redirect=${router.currentRoute.query.redirect ? router.currentRoute.query.redirect : router.currentRoute.fullPath}`)
                break;
            case 403:
                Message({
                    message: error.response.data.error.message,
                    duration: 2000,
                    forbidClick: true
                });
                break;
            case 404:
                Message({
                    message: '网络请求不存在',
                    duration: 2000,
                    forbidClick: true
                });
                break;
            case 405:
                Message({
                    message: 'Method not allowed',
                    duration: 2000,
                    forbidClick: true
                });
                break;
            case 500:
                Message({
                    message: '服务器异常',
                    duration: 2000,
                    forbidClick: true
                });
                break;
            default:
                Message({
                    message: error.response.data.error.message,
                    duration: 1500,
                    forbidClick: true
                })
        }
    }
    return Promise.reject(error) // 返回执行错误， 让当前的执行链跳出成功，直接进入 catch
})

export default service;
