import request from '@/utils/request'
import request3 from '@/utils/request3'

export const getMap = url => {
    return request({
        method: 'GET',
        url
    })
}


export const getSurNameCount = params => {
    return request({
        method: 'POST',
        url: 'knowledgeshow/magrate-appservice/group-by-migrate-detail-by-surname',
        params
    })
}

export const getBeginPlace = params => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/magrate-appservice/groupby-migrate-detail-by-begin-place',
        params
    })
}

export const getCount = params => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/magrate-appservice/return-migrate-detail-count',
        params
    })
}

export const placeAndSurname = params => {
    return request({
        method: 'POST',
        url: '/knowledgeshow/magrate-appservice/groupby-migrate-detail-by-begin-place-and-surname',
        params
    })
}

export const getCountryInfos = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/extent/item-by-area',
        params
    })
}

export const getCountrySameNameInfos = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/country-same-name/by-type',
        params
    })
}

// 获取地区信息详情
export const getInfoByArea = params => {
    return request({
        method: 'GET',
        url: '/knowledgeshow/country-same-name/county-city-info-by-area',
        params
    })
}

// 获取负责人详情
export const getPersonInfo = id => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/country-same-name/${id}/person-by-id`
    })
}


// 语音转文字

export const speechToText = (params, data) => {
    return request3({
        method: 'POST',
        url: `/asr`,
        params,
        data
    })
}


// 获取历史记录
export const getHistoryMes = (params) => {
    return request({
        method: 'GET',
        url: `/userrole/g-pTMessage/g-pTMessage-list`,
        params,
    })
}

// 新建对话
export const newTalk = (data) => {
    return request({
        method: 'POST',
        url: `/userrole/g-pTMessage/g-pTMessage`,
        data,
    })
}

// 评价
export const evaluate = (data) => {
    return request({
        method: 'POST',
        url: `/userrole/g-pTMessage-comments/g-pTMessage-comments`,
        data,
    })
}

// // 问答系统获取迁徙记录详情
// export const getDetail = (params) => {
//     return request({
//         method: 'GET',
//         url: `/knowledgeshow/magrate-appservice/person-migrate-detail`,
//         params,
//     })
// }
// 问答系统获取迁徙记录详情
export const getDetail = (id) => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/magrate-appservice/person-migrate-detail-list/${id}`,
    })
}

// 留言
export const getCommentList = (params) => {
    return request({
        method: 'GET',
        url: `/userrole/g-pTMessage-comments/g-pTMessage-comments-list`,
        params,
    })
}

// 查询是否有世系树
export const searchTree = (params) => {
    return request({
        method: 'GET',
        url: `/knowledge/member/document-by-source-iD`,
        params,
    })
}

// 查询是否还能问答
export const getMessageNum = (params) => {
    return request({
        method: 'POST',
        url: `/userrole/user/check-gPTMessage-limit`,
        params
    })
}

// 祖地族谱
export const getGenealogy = params => {
    return request({
        method: 'GET',
        url: `/knowledgeshow/genealogy`,
        params
    })
}

// 上传图片
export const saveFiles = (id, data) => {
    return request({
        method: 'PUT',
        url: `/userrole/g-pTMessage-comments/upload-comment-pictures`,
        data,
        params: {
            GPTMessageID: id
        },
        headers: {
            'content-type': 'multipart/form-data;boundary=${boundary}'
        }
    })
}

// 提交识别
export const createDiscernTask = (data) => {
    return request({
        method: 'POST',
        url: `/discerntask/g-pTMessage-discern/create-task`,
        data
    })
}

// 通过id获取评论
export const getCommentById = (id) => {
    return request({
        method: 'GET',
        url: `/userrole/g-pTMessage-comments/${id}/g-pTMessage-comment-by-iD`,
    })
}




