<template>
  <div class="chat-box">
    <div class="header" v-if="!isMobile">
      <div class="wrap nav">
        <div class="left">
          <img class="img" src="../../assets/tbzn.png" />
        </div>
      </div>
    </div>

    <div class="box">
      <div
        @click="closeDialog"
        class="aside"
        :style="{
          background: isMobile ? '#fff' : '#135291',
          color: isMobile ? '#000' : '#fff',
        }"
        ref="aside"
      >
        <h4>
          <div @click="addNewTalk">
            <i
              class="el-icon-circle-plus-outline"
              :style="{ color: isMobile ? '#000' : '#fff' }"
            ></i>
            <span
              :style="{
                color: isMobile ? '#000' : '#000',
                background: isMobile ? '#fff' : '#7ecef4',
                padding: isMobile ? 0 : '10px 15px',
                borderRadius: '10px',
                marginLeft: '15px',
              }"
              >新建對話</span
            >
          </div>
          <i
            class="el-icon-circle-close"
            @click="onAsideShow('close')"
            v-show="!isMobileFn"
          ></i>
        </h4>
        <ul @scroll="onScroll" v-loading="getListLoading" ref="scroll">
          <li
            v-for="item in historyList"
            :key="item.id"
            @click="viewHistory(item)"
          >
            {{ item.question }}
          </li>
        </ul>
        <template v-if="!isMobile">
          <!-- <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img src="../../assets/code1.png" alt="" />
            <span style="font-size: 20px">掃碼免註冊體驗</span>
          </div> -->
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
            "
          >
            <img src="../../assets/code.png" alt="" />
            <span style="font-size: 20px">掃碼註冊體驗</span>
          </div>
        </template>
        <!--<div class="code" v-if="!isMobile">-->
        <!--  &lt;!&ndash; text="https://xungen.guanshutangocr.com/#/login/?type=1" &ndash;&gt;-->
        <!--  <vue-qr-->
        <!--      text="https://xungen.guanshutangocr.com/#/login/robotmigrate"-->
        <!--      :size="200"-->
        <!--      :logoScale="0.2"-->
        <!--  >-->
        <!--  </vue-qr>-->
        <!--</div>-->

        <div class="user" :class="{ user1: !isMobile }">
          <div class="info" v-show="isShowUserInfo">
            <ul>
              <li @click="onClick('one')">
                <i class="el-icon-user icon"></i><i>賬戶信息</i>
              </li>
              <li @click="onClick('two')">
                <i class="el-icon-setting icon"></i><i>修改密碼</i>
              </li>
              <li @click="onClick('three')">
                <i class="el-icon-chat-dot-square icon"></i><i>消息通知</i>
              </li>
              <li @click="onClick('four')">
                <i class="el-icon-chat-line-square icon"></i><i>留言記錄</i>
              </li>
              <li @click="onClick('five')">
                <i class="el-icon-present icon"></i><i>中獎記錄</i>
              </li>
            </ul>
            <hr />
            <div class="logout" @click="logout">
              <i class="el-icon-open icon"></i><i>退出登錄</i>
            </div>
          </div>
          <div class="avatar" @click="changeUserInfoVisible">
            <el-avatar size="large" :src="userAvatar" class="img"> </el-avatar>
            <span>我的賬戶</span>
          </div>
        </div>
      </div>

      <div class="mask" v-if="isShowMask" @click="onAsideShow('close')"></div>

      <div style="padding-top: 5px" class="test" v-show="rec">
        <!-- 波形绘制区域 -->
        <div
          style="
            border: 1px solid #ccc;
            display: inline-block;
            vertical-align: bottom;
          "
        >
          <div style="height: 100px; width: 300px" ref="recwave"></div>
        </div>
      </div>

      <div class="content" :class="{ back: !isMobile }">
        <div v-show="!isMobile" class="gs">
          <span>福建省閩臺宗祠文化研究會</span>
        </div>

        <div class="title" v-if="!isMobile">
          <div class="input1">
            <el-input
              style="font-size: large"
              @keyup.enter.native="handleSend"
              placeholder="請輸入姓氏"
              v-model="txtSurname"
            >
              <p class="icon" slot="prefix">尋根|貴姓</p>
            </el-input>
            <el-input
              class="area1"
              style="font-size: large"
              @keyup.enter.native="handleSend"
              placeholder="請輸入臺灣祖地"
              v-model="txtFromArea"
            >
              <p class="icon" slot="prefix">尋根|從哪裡來</p>
            </el-input>

            <el-input
              class="name1"
              style="font-size: large"
              placeholder="請輸入遷臺祖名字"
              @keyup.native.enter="handleSend"
              v-model="txtFromName"
            >
              <p class="icon" slot="prefix">寻根|祖上尊名</p>
            </el-input>
            <el-button
              class="btn1"
              icon="el-icon-search"
              :loading="loading"
              @click="handleSend"
            ></el-button>
          </div>
        </div>

        <div class="title-box" v-else>
          <i class="el-icon-s-unfold" @click="onAsideShow"></i>
          <!-- <p class="temp"></p> -->
          <span>臺胞尋根智慧平臺</span>
          <p class="temp"></p>
        </div>

        <ul class="dialogue" ref="scrollBox" @scroll="handleWheel">
          <template v-if="questionList.length">
            <li
              v-for="(item, index) in questionList"
              :key="item.id"
              :class="{ rowReverse: item.type === 'you' }"
            >
              <div class="user-ai-box">
                <!-- <img :src="youImg" alt="" /> -->
                <template v-if="item.type === 'you'">
                  <span :style="{ color: isMobile ? '#000' : '#fff' }">{{
                    item.name
                  }}</span>
                  <el-avatar size="large" :src="userAvatar" class="img">
                  </el-avatar>
                </template>
                <template v-else>
                  <el-avatar size="large" :src="robotAvatar" class="img">
                  </el-avatar>
                  <span :style="{ color: isMobile ? '#000' : '#fff' }">{{
                    item.name
                  }}</span>
                </template>
              </div>
              <div style="margin: 0 8px">
                <!-- @click="boFangClick($event, index)" -->
                <p
                  v-html="
                    `${item.title ? item.title + item.value : item.value}`
                  "
                  :style="{ fontSize: '18px' }"
                  :class="
                    !isMobile ? (item.type === 'you' ? 'class2' : 'class1') : ''
                  "
                ></p>
                <!-- <div
                  class="audio-box"
                  v-if="item.type !== 'you' && item.audioShow"
                  v-loading="item.audioLoading"
                >
                  <audio
                    controls=""
                    preload="metadata"
                    :src="item.audioSrc"
                    autoplay
                  ></audio>
                </div> -->
                <p
                  v-show="
                    item.type !== 'you' &&
                    item.show &&
                    isToolShow &&
                    $store.state.user.user.userName === 'admin'
                  "
                  :style="{
                    fontSize: isMobile ? '16px' : '18px',
                    marginTop: '5px',
                    color: isMobile ? '#000' : '#fff',
                    cursor: 'pointer',
                    marginRight: '10px',
                  }"
                  class="el-icon-view"
                  @click="showDetail(item.id)"
                >
                  查看遷徙來源
                </p>

                <el-popover
                  placement="bottom"
                  trigger="click"
                  @show="onPopoverShow(item)"
                  @hide="onPopoverHide"
                >
                  <el-form
                    ref="form"
                    :model="form"
                    label-width="100px"
                    :style="{
                      padding: '0 10px',
                      height: !isMobile ? '450px' : 'auto',
                      width: !isMobile ? '500px' : 'auto',
                      overflow: 'auto',
                    }"
                  >
                    <el-form-item label="是否滿意">
                      <el-radio-group v-model="form.isSatisfied">
                        <el-radio :label="1">非常满意</el-radio>
                        <el-radio :label="0">检索不到</el-radio>
                        <el-radio :label="2">信息不准确</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <template v-if="form.isSatisfied !== 1">
                      <el-form-item label="姓氏">
                        <el-input
                          v-model="form.userSurname"
                          @input="onchange()"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="臺灣祖籍">
                        <el-input
                          v-model="form.userTaiWanAncestralHome"
                          @input="onchange()"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label="留下更多的尋根線索">
                        <el-input
                          type="textarea"
                          v-model="form.message"
                        ></el-input>
                      </el-form-item>
                    </template>
                    <el-form-item label="留言" v-else>
                      <el-input
                        type="textarea"
                        v-model="form.message"
                      ></el-input>
                    </el-form-item>
                    <!-- <template v-if="isMobile && form.isSatisfied !== 1">
                      <el-form-item label="上傳族譜圖片">
                        <img
                          src="../../assets/sbie.jpg"
                          style="
                            display: inline-block;
                            width: 128px;
                            height: 128px;
                          "
                          alt=""
                        />
                      </el-form-item>
                    </template> -->
                    <el-form-item label="上傳族譜圖片">
                      <!-- <el-upload
                        action="#"
                        list-type="picture-card"
                        :auto-upload="false"
                      >
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{ file }">
                          <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url"
                            alt=""
                          />
                          <span class="el-upload-list__item-actions">
                            <span
                              class="el-upload-list__item-preview"
                              @click="handlePictureCardPreview(file)"
                            >
                              <i class="el-icon-zoom-in"></i>
                            </span>
                            <span
                              v-if="!disabled"
                              class="el-upload-list__item-delete"
                              @click="handleDownload(file)"
                            >
                              <i class="el-icon-download"></i>
                            </span>
                            <span
                              v-if="!disabled"
                              class="el-upload-list__item-delete"
                              @click="handleRemove(file)"
                            >
                              <i class="el-icon-delete"></i>
                            </span>
                          </span>
                        </div>
                      </el-upload> -->
                      <ul class="upload">
                        <li
                          class="u-li"
                          v-for="(item, index) in fileList"
                          :key="item.uid"
                        >
                          <el-image
                            :src="item.url"
                            :alt="item.name"
                            fit="scale-down"
                          ></el-image>
                          <span class="upload-list-icon">
                            <span @click="handleRemove(index)" title="删除">
                              <i
                                class="el-icon-delete"
                                style="color: #f56c6c"
                              ></i>
                            </span>
                          </span>
                        </li>
                        <li>
                          <el-upload
                            action="#"
                            multiple
                            accept="image/*"
                            list-type="picture-card"
                            :on-change="handleChange"
                            :file-list="fileList"
                            :auto-upload="false"
                            :with-credentials="true"
                            :show-file-list="false"
                          >
                            <!-- :on-exceed="handleExceed" -->
                            <!-- :limit="limit" -->
                            <i slot="default" class="el-icon-plus"></i>
                          </el-upload>
                        </li>
                      </ul>
                    </el-form-item>
                    <el-form-item>
                      <template v-if="form.isSatisfied != 1 && !isMobile">
                        <el-button type="primary" @click="onSubmit(item.msgId)">
                          提交
                        </el-button>
                      </template>

                      <el-button
                        v-else
                        type="primary"
                        @click="onSubmit(item.msgId)"
                        >提交
                      </el-button>
                    </el-form-item>
                  </el-form>
                  <p
                    slot="reference"
                    v-show="item.type !== 'you' && item.show"
                    :style="{
                      fontSize: isMobile ? '16px' : '18px',
                      marginTop: '5px',
                      color: isMobile ? '#000' : '#fff',
                      cursor: 'pointer',
                    }"
                    class="el-icon-chat-line-square"
                  >
                    留言評價
                  </p>
                </el-popover>

                <p
                  @click="genClick(item.question, item.addressList)"
                  v-show="
                    item.type !== 'you' && item.show && isToolShow && !isMobile
                  "
                  :style="{
                    fontSize: isMobile ? '16px' : '18px',
                    marginTop: '5px',
                    color: isMobile ? '#000' : '#fff',
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }"
                  class="el-icon-document"
                >
                  祖地族譜
                </p>
                <p
                  v-html="item.sourceValue"
                  style="display: none; font-size: 18px"
                  :id="'liTab' + item.id"
                  @click="gotoG"
                  :class="{ litab: !isMobile }"
                ></p>
              </div>
            </li>
          </template>
          <div class="help" v-else>
            <!-- <p style="font-size: 20px">客从何处来</p>
            <P style="font-size: 20px">尋根|貴姓|-输入姓氏</P>
            <P style="font-size: 20px">寻根|从哪来|-输入臺湾祖地</P>
            <P style="font-size: 20px">寻根|祖上尊名|-输入迁臺祖名字</P> -->

            <div class="a" v-if="!isMobile">
              <p
                :style="{ fontSize: '20px', color: isMobile ? '#000' : '#fff' }"
              >
                我是<em>阿根</em>，一個想為臺灣鄉親尋找大陸祖地的AI小夥伴。
              </p>
              <p
                :style="{ fontSize: '20px', color: isMobile ? '#000' : '#fff' }"
              >
                只要您告訴<em>阿根</em
                ><em>“您貴姓，臺灣祖籍地或者您祖先的名字”</em
                >，也許<em>阿根</em>會給您一個驚喜，回答您祖先的遷臺記憶，找到您在大陸的祖地；如果<em>阿根</em>暫時不能給您滿意的答案，沒關係，只要您留下尋根信息，<em>阿根</em>會盡力為您解決問題。
              </p>
              <p
                :style="{ fontSize: '20px', color: isMobile ? '#000' : '#fff' }"
              >
                準備好了嗎？讓<em>阿根</em>為您開啟尋根之旅！
              </p>
              <div style="display: flex; justify-content: space-around">
                <!-- <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <img
                    src="../../assets/code1.png"
                    alt=""
                    style="margin: 20px 0"
                  />
                  <span style="color: #fff; font-size: 20px"
                    >掃碼免註冊體驗</span
                  >
                </div> -->
                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <img
                    src="../../assets/code.png"
                    alt=""
                    style="margin: 20px 0"
                  />
                  <span style="color: #fff; font-size: 20px">掃碼註冊體驗</span>
                </div>
              </div>
            </div>
            <template v-else>
              <p
                :style="{ fontSize: '20px', color: isMobile ? '#000' : '#fff' }"
              >
                我是阿根，一個想為臺灣鄉親尋找大陸祖地的AI小夥伴。
              </p>
              <p
                :style="{ fontSize: '20px', color: isMobile ? '#000' : '#fff' }"
              >
                只要您告訴阿根“您貴姓，臺灣祖籍地或者您祖先的名字”，也許阿根會給您一個驚喜，回答您祖先的遷臺記憶，找到您在大陸的祖地；如果阿根暫時不能給您滿意的答案，沒關係，只要您留下尋根信息，阿根會盡力為您解決問題。
              </p>
              <p
                :style="{ fontSize: '20px', color: isMobile ? '#000' : '#fff' }"
              >
                準備好了嗎？讓阿根為您開啟尋根之旅！
              </p>
            </template>
          </div>
        </ul>

        <div class="input" v-if="isMobile">
          <div class="name">
            <p>尋根|貴姓</p>
            <el-input placeholder="請輸入姓氏" v-model="txtSurname"></el-input>
          </div>
          <div class="area-box">
            <div class="area">
              <p>寻根|从哪来</p>
              <el-input
                placeholder="請輸入臺灣祖地"
                @keyup.native.enter="handleSend"
                v-model="txtFromArea"
              >
              </el-input>
            </div>
            <div class="area">
              <p>寻根|祖上尊名</p>
              <el-input placeholder="請輸入遷臺祖名字" v-model="txtFromName">
              </el-input>
            </div>
          </div>
          <div v-if="isMobile" style="display: flex; width: 100%">
            <van-button
              style="flex: 1"
              :loading="loading"
              @click="handleSend"
              icon="search"
            />
            <van-button
              style="flex: 1"
              :loading="loading1"
              :loading-text="loadingText"
              @touchstart.native.prevent="touchstart"
              @touchend.native.prevent="touchend"
              @touchmove="touchmove"
            >
              <template #icon>
                <van-icon
                  class="iconfont"
                  class-prefix="icon"
                  name="maikefeng"
                ></van-icon>
              </template>
            </van-button>
          </div>
        </div>

        <!-- <satisfied
          @back="back"
          v-if="isSatisfiedShow"
          @goGenealogy="goGenealogy"
        /> -->
        <reward @back="back" :num="num" v-if="isReward" :showCode="showCode" />
        <!-- <genealogy
          :address-list="addressList"
          @close="onclose"
          v-if="isGenealogyShow"
        /> -->
      </div>
    </div>

    <el-dialog
      :id="isMobile ? 'dlog1' : 'dlog'"
      :visible.sync="isPicShow"
      :style="{ height: '100vh' }"
      @close="isPicShow = false"
      width="70%'"
      title="原圖"
      :fullscreen="isMobile"
    >
      <div :class="!isMobile ? 'dialog_box' : 'dialog_box_mobile'">
        <div class="left" v-dragscroll>
          <el-image
            @load="onload"
            @error="onerror"
            :src="`/api/fileinfo/image/GetOriImage?filename=${infoObjPictureFullName}&ocrSource=1`"
            fit="cover"
            v-resize
            style="height: 100%; transform-origin: left top"
            @click="imgClick"
          />
        </div>

        <div class="right" v-if="infoObj">
          <div v-for="item in infoObj" :key="item.id">
            <p style="margin: 10px 0; font-size: 18px">
              <strong>名称：</strong><strong>{{ item.title }}</strong>
            </p>
            <div
              style="
                font-size: 18px;
                line-height: 1.75;
                display: flex;
                flex-wrap: wrap;
              "
            >
              <p style="margin: 10px 50px 10px 0" v-if="item.publisher">
                <span>編者：</span>
                <span>{{ item.publisher }}</span>
              </p>
              <p style="margin: 10px 50px 10px 0" v-if="item.dateOfPublication">
                <span>出版日期：</span>
                <span>{{ item.dateOfPublication }}</span>
              </p>
              <p style="margin: 10px 50px 10px 0" v-if="item.copies">
                <span>卷冊數：</span>
                <span>{{ item.copies }}</span>
              </p>
              <p style="margin: 10px 50px 10px 0" v-if="item.ancestor">
                <span>始祖：</span>
                <span>{{ item.ancestor }}</span>
              </p>
              <p style="margin: 10px 50px 10px 0" v-if="item.owner">
                <span>收藏單位：</span>
                <span>{{ item.owner }}</span>
              </p>
            </div>
            <div style="margin: 10px 0; font-size: 18px">
              <p style="margin-bottom: 10px">遷徙記錄：</p>
              <p style="margin-bottom: 10px; line-height: 1.5">
                <i style="font-style: normal">{{ item.personalName }}：</i>
                <span
                  infoObj-if="item.migrateDate || item.migrateDateYear"
                  v-show="item.migrateDate || item.migrateDateYear"
                  >({{ item.migrateDate
                  }}{{
                    item.migrateDateYear ? item.migrateDateYear : ""
                  }})，</span
                >
                <span
                  >{{ item.beginPlace.replace("#", "") }}({{
                    item.beginPlaceAddress
                  }})</span
                >
                &nbsp;
                <img
                  style="width: 18px; vertical-align: middle"
                  src="../../assets//image/前往.png"
                />&nbsp;
                <span
                  >{{ item.endPlace.replace("#", "") }}({{
                    item.endPlaceAddress
                  }})</span
                >
              </p>
              <p style="margin-bottom: 10px">
                <span style="line-height: 1.5"
                  >遷徙信息：<span
                    v-html="highlight(item.sourceText, item.beginPlace)"
                  ></span
                ></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="el-dialog__footer"
        style="display: flex; justify-content: center; align-items: center"
      >
        <el-button @click="Before">上一頁</el-button>
        <el-button @click="After">下一頁</el-button>
      </div>
    </el-dialog>

    <el-dialog
      id="dlog"
      :visible.sync="isEbookShow"
      style="height: 100vh"
      @close="isEbookShow = false"
      width="70%"
      title="電子書"
    >
      <div class="dialog_box">
        <div class="left" v-dragscroll>
          <el-image
            v-resize
            :src="`/api/fileinfo/image/GetOriImage?filename=${pictureFullName}`"
            style="height: 100%; transform-origin: left top"
            fit="cover"
          />
        </div>
      </div>
    </el-dialog>

    <div class="overlay" id="overlay" v-if="popupShow">
      <div class="popup">
        <div class="popup-header">
          <h2>{{ popup.title }}</h2>
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="popupShow = false"
          ></i>
        </div>
        <div class="popup-content">
          <el-form
            ref="form"
            :model="userForm"
            label-position="left"
            label-width="90px"
          >
            <template v-if="popup.isUpdateUser">
              <el-form-item label="姓氏">
                <el-input v-model="userForm.surname"></el-input>
              </el-form-item>
              <el-form-item label="臺灣祖籍地">
                <el-input v-model="userForm.taiWanAncestralHome"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="updateUserInfo"
                  >提交
                </el-button>
                <el-button @click="popupShow = false">取消</el-button>
              </el-form-item>
            </template>
            <template v-if="popup.isUpdatePassword">
              <el-form-item label="舊密碼">
                <el-input v-model="userForm.oldPassword"></el-input>
              </el-form-item>
              <el-form-item label="新密碼">
                <el-input
                  v-model="userForm.newPassword"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item label="確認密碼">
                <el-input
                  v-model="userForm.confirmNewPassword"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="updateUserInfo('password')"
                  >提交
                </el-button>
                <el-button @click="popupShow = false">取消</el-button>
              </el-form-item>
            </template>
          </el-form>
        </div>
      </div>
    </div>

    <div class="overlay" id="overlay" v-if="messageShow">
      <div class="popup" style="width: 50%">
        <div class="popup-header">
          <h2>留言記錄</h2>
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="messageShow = false"
          ></i>
        </div>
        <div
          style="text-align: left; margin-bottom: 10px"
          v-if="!this.isMobile"
        >
          <el-date-picker
            v-model="dateTimeList"
            type="datetimerange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
          <el-select v-model="IsSatisfied">
            <el-option
              v-for="item in stateList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select v-model="IsHandled">
            <el-option
              v-for="item in handledList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button type="primary" plain @click="onSearch">查詢</el-button>
        </div>
        <div class="popup-content">
          <el-table
            :data="commentList"
            style="width: 100%; min-height: 500px"
            max-height="500px"
          >
            <el-table-column
              prop="commentTime"
              label="時間"
              :show-overflow-tooltip="true"
            >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  scope.row.commentTime
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="isSatisfied" label="是否滿意">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  scope.row.isSatisfied ? "满意" : "不满意"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="isHandled" label="狀態">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  scope.row.isHandled ? "已处理" : "未处理"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="userSurname" label="姓氏"></el-table-column>
            <el-table-column
              prop="userTaiWanAncestralHome"
              label="臺灣祖籍"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="message"
              label="留言內容"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="previewSrcList(scope.row)"
                  type="text"
                  size="small"
                  v-show="scope.row.commentPictures.length"
                  >查看评价上传图片
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="overlay" id="overlay" v-if="prizeShow">
      <div class="popup" style="width: 50%">
        <div class="popup-header">
          <h2>中獎記錄</h2>
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="prizeShow = false"
          ></i>
        </div>
        <div class="popup-content">
          <el-table
            :data="paramsList"
            style="width: 100%; min-height: 500px"
            max-height="500px"
          >
            <el-table-column
              prop="title"
              label="標題"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="remark"
              label="内容"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="創建時間"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="redeemStatus"
              label="兌現狀態"
              :show-overflow-tooltip="true"
              :formatter="redeemFormat"
            >
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  @click="onExchange(scope.row)"
                  >兌現
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="overlay" id="overlay" v-if="userMsgShow">
      <div class="popup" style="width: 50%">
        <div class="popup-header">
          <h2>消息列表</h2>
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="userMsgShow = false"
          ></i>
        </div>
        <div
          style="text-align: left; margin-bottom: 10px"
          v-if="!this.isMobile"
        >
          <el-date-picker
            v-model="ReceiveDateTimeList"
            type="datetimerange"
            range-separator="至"
            start-placeholder="開始日期"
            end-placeholder="結束日期"
          >
          </el-date-picker>
          <el-select v-model="IsReceive">
            <el-option
              v-for="item in receiveList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button type="primary" plain @click="onReceiveSearch"
            >查詢
          </el-button>
        </div>
        <div class="popup-content">
          <el-table
            :data="userMsgList"
            style="width: 100%; min-height: 500px"
            max-height="500px"
          >
            <el-table-column
              prop="title"
              label="標題"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="sendTime"
              label="時間"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="content"
              label="內容"
              :show-overflow-tooltip="true"
            >
            </el-table-column>
            <el-table-column
              prop="isReceive"
              label="狀態"
              width="100px"
              sortable
            >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  scope.row.isReceive ? "已讀" : "未讀"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="50px">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="readMsg(scope)">
                  查看
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>

    <div class="overlay" id="overlay" v-if="readMsgShow">
      <div class="popup" style="width: 30%">
        <div class="popup-header">
          <h2>{{ msgInfoObj.title }}</h2>
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="readMsgShow = false"
          ></i>
        </div>
        <div class="popup-content read">
          <p>問題： {{ msgInfoObj.gptMessage.question }}</p>
          <p>回答： {{ msgInfoObj.gptMessage.answer }}</p>
          <p class="br" v-html="msgInfoObj.gptMessage.remark"></p>

          <p>評論時間：{{ msgInfoObj.gptMessageComments.commentTime }}</p>
          <p>
            評論狀態：{{
              msgInfoObj.gptMessageComments.isSatisfied ? "滿意" : "不滿意"
            }}
          </p>
          <p>姓氏：{{ msgInfoObj.gptMessageComments.userSurname }}</p>
          <p>
            臺灣祖籍地：{{
              msgInfoObj.gptMessageComments.userTaiWanAncestralHome
            }}
          </p>
          <p class="br">內容：{{ msgInfoObj.gptMessageComments.message }}</p>

          <p>回復時間：{{ msgInfoObj.sendTime }}</p>
          <p>回復標題：{{ msgInfoObj.title }}</p>
          <p>回復內容：{{ msgInfoObj.content }}</p>
        </div>
      </div>
    </div>

    <div class="overlay" id="overlay" v-if="isPreviewImg">
      <div class="popup" style="width: 50%; height: 650px">
        <div class="popup-header">
          <h2>已上传图片</h2>
          <i
            class="el-icon-close"
            style="cursor: pointer"
            @click="isPreviewImg = false"
          ></i>
        </div>
        <div class="popup-content upload-img">
          <ul>
            <li v-for="item in srcList" :key="item.id">
              <div v-if="item.discernState !== 1" class="triangle">
                <span>
                  {{ item.discernState === 0 ? "未识别" : "识别中" }}</span
                >
              </div>
              <el-image
                style="width: 100px; height: 100px"
                :src="`/api/fileinfo/image/GetGPTMessageCommentImageGetOriImageThumbnail?filename=${item.picturePath}${item.pictureFileName}`"
              ></el-image>
              <div class="icon">
                <i class="el-icon-view" @click="onPreview" title="预览原图"></i>
                <i
                  class="el-icon-upload2"
                  title="送入识别"
                  v-if="item.discernState === 0"
                  @click="sendForRecognition(item.id)"
                ></i>
                <i
                  class="el-icon-edit-outline"
                  title="单页校对"
                  v-if="item.discernState === 1"
                  @click="goToProofread(item.documentOCRInfoId)"
                ></i>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <el-image-viewer
      v-if="showImgViewer"
      :on-close="
        () => {
          showImgViewer = false;
        }
      "
      :url-list="imgViewerList"
    />

    <van-image-preview
      style="z-index: 2007"
      v-model="mobileImgPreview"
      :images="[
        `/api/fileinfo/image/GetOriImage?filename=${infoObjPictureFullName}&ocrSource=1`,
      ]"
    >
    </van-image-preview>
  </div>
</template>
<script >
import VueQr from "vue-qr";
import robotAvatar from "../../assets/robot/xst.png";
import userAvatar from "../../assets/robot/男头像.png";
import elHeader from "../../components/headerPage";
import reward from "./components/reward.vue";
import satisfied from "./components/satisfied.vue";
import genealogy from "./components/genealogy.vue";
import {
  getMap as getAddress,
  speechToText,
  getHistoryMes,
  newTalk,
  evaluate,
  getDetail,
  getCommentList,
  searchTree,
  getMessageNum,
  saveFiles,
  createDiscernTask,
  getCommentById,
} from "../../api/drillingIn";
import { BeforeOrNext } from "@/api/susername";
import {
  updateUser,
  updatePassword,
  getMsg,
  readMsg,
  getJackPot,
  redeemJackpot,
} from "../../api/user";
import { client } from "../../api/gradio";
import { Loading } from "element-ui";
//必须引入的核心，换成require也是一样的。注意：recorder-core会自动往window下挂载名称为Recorder对象，全局可调用window.Recorder，也许可自行调整相关源码清除全局污染
import Recorder from "recorder-core";

//引入相应格式支持文件；如果需要多个格式支持，把这些格式的编码引擎js文件放到后面统统引入进来即可
import "recorder-core/src/engine/mp3";
import "recorder-core/src/engine/mp3-engine"; //如果此格式有额外的编码引擎（*-engine.js）的话，必须要加上

//以上三个也可以合并使用压缩好的recorder.xxx.min.js
//比如 import Recorder from 'recorder-core/recorder.mp3.min' //已包含recorder-core和mp3格式支持

//可选的插件支持项
import "recorder-core/src/extensions/waveview";
import { mapState } from "vuex";
import wx from "weixin-js-sdk";

//ts import 提示：npm包内已自带了.d.ts声明文件（不过是any类型）

export default {
  components: {
    elHeader,
    VueQr,
    satisfied,
    reward,
    genealogy,
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      audioLoading: false,
      audioApp: null,
      oldScrollTop: 0,
      showCode: false,
      paramsList: [],
      prizeShow: false,
      reachBottomDistance: 50,
      offsetHeight: null,
      getListLoading: false,
      false: true,
      total: 0,
      PageIndex: 1,
      num: 0,
      // addressList: [],
      // isSatisfiedShow: false,
      isReward: false,
      isGenealogyShow: false,
      mobileImgPreview: false,
      userAvatar: userAvatar,
      robotAvatar: robotAvatar,
      gptMessageID: "",
      userGPTCommentID: "",
      imgViewerList: [],
      showImgViewer: false,
      srcList: [],
      isPreviewImg: false,
      infoObjPictureFullName: "",
      ReceiveDateTimeList: [],
      IsReceive: 0,
      receiveList: [
        { value: 0, label: "全部" },
        { value: 1, label: "已讀" },
        { value: 2, label: "未讀" },
      ],
      IsHandled: 0, // 0 全部，1已处理，2未处理
      IsSatisfied: 0, // 0全部，1满意，2不满意
      dateTimeList: [],
      stateList: [
        { value: 0, label: "全部" },
        { value: 1, label: "滿意" },
        { value: 2, label: "不滿意" },
      ],
      handledList: [
        { value: 0, label: "全部" },
        { value: 1, label: "已處理" },
        { value: 2, label: "未處理" },
      ],
      isToolShow: true,
      genealogyOCRInfoID: "",
      loadingInstance: "",
      options: {
        fullscreen: true,
        lock: true,
        text: "Loading",
        target: ".el-dialog__body",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      },
      readMsgShow: false,
      msgInfoObj: {},
      userMsgShow: false,
      userMsgList: [],
      loadingText: "",
      loading1: false,
      commentList: [],
      popup: {
        title: "",
      },
      messageShow: false,
      popupShow: false,
      userForm: {},
      pictureFullName: "",
      isEbookShow: false,
      isGenealogy: false,
      infoObj: [],
      isPicShow: false,
      isShowUserInfo: false, // 账户信息选项卡显隐
      currentMsgId: "",
      historyList: [],
      form: {
        isSatisfied: 1,
      },
      rec: null,
      isMobile: false,
      isShowMask: false,
      loading: false,
      scrollBox: "",
      // youImg: require("../../assets/banner.png"),
      app: "",
      questionList: [],
      questionIndex: 1,
      txtSurname: "",
      txtFromArea: "",
      txtFromName: "",
      compoundSurname: [
        "欧阳",
        "慕容",
        "诸葛",
        "上官",
        "司马",
        "东方",
        "宇文",
        "西门",
        "令狐",
        "夏侯",
        "司徒",
        "公孙",
        "长孙",
        "独孤",
        "皇甫",
        "百里",
        "拓跋",
        "南宫",
        "纳兰",
        "端木",
      ],
      isAutoScrollEnabled: true,
      fileList: [],
      // limit: 5,
      uploadThreadCount: 2,
      uploadManyCount: 5,
      GPTMessageID: "",
      isRequestCommentById: true,
    };
  },

  computed: {
    ...mapState("user", ["user"]),
  },
  created() {
    this.init();
    this.isMobileFn();
    this.getHistoryList();
  },
  mounted() {
    let dom = this.$refs["scroll"];
    this.offsetHeight = Math.ceil(dom.getBoundingClientRect().height);
    this.scrollBox = this.$refs.scrollBox;
  },
  watch: {
    messageShow(val) {
      if (!this.isMobile) return;
      if (val) {
        document.body.style.position = "fixed";
        document.body.style.overflow = "hidden";
        document.querySelector("#app").style.overflow = "hidden";
      } else {
        document.body.style.position = "relative";
        document.body.style.overflow = "scroll";
        document.querySelector("#app").style.overflow = "scroll";
      }
    },
  },
  methods: {
    // async boFangClick(e, index) {
    //   if (e.target.nodeName === "IMG") {
    //     if (this.questionList[index].audioLoading) return;
    //     this.questionList[index].audioShow = true;
    //     this.questionList[index].audioLoading = true;
    //     if (this.questionList[index][e.target.dataset.id]) {
    //       this.questionList[index].audioSrc =
    //         this.questionList[index][e.target.dataset.id];
    //     } else {
    //       const res = await this.convertToSpeech(e.target.dataset.str);
    //       this.questionList[index][e.target.dataset.id] = res;
    //       this.questionList[index].audioSrc = res;
    //     }
    //   }
    //   this.questionList[index].audioLoading = false;
    // },
    async onExchange(row) {
      if (row.redeemStatus === 0) {
        await redeemJackpot(row.id);
        this.getJackPot();
      }
      this.num = row.jackpotNum;
      this.showCode = true;
      this.isReward = true;
    },
    closeDialog() {
      // this.isSatisfiedShow = false;
      this.isReward = false;
      this.isGenealogyShow = false;
    },
    genClick(question, address) {
      // this.addressList = address;
      this.$store.commit("user/SET_ADDRESS_LIST", { question, address });
      document.body.click();
      console.log(this.$store.state.user);
      // this.isSatisfiedShow = true;
      const { href } = this.$router.resolve({
        path: this.isMobile
          ? "/ancestralGenealogyMobile"
          : "/ancestralGenealogy",
        query: {
          question,
        },
      });
      window.open(href, "_blank");
    },
    redeemFormat(row) {
      if (row.redeemStatus === 0) {
        return "未兌現";
      } else if (row.redeemStatus === 1) {
        return "已聯係管理員";
      } else if (row.redeemStatus === 2) {
        return "管理員以兌獎";
      } else {
        return "";
      }
    },
    onScroll(e) {
      let scrollTop = e.target.scrollTop;
      let scrollHeight = e.target.scrollHeight;
      let currentHeight =
        scrollTop + this.offsetHeight + this.reachBottomDistance;
      if (currentHeight >= scrollHeight && this.flag) {
        console.log("触底");
        this.flag = false;
        this.PageIndex++;
        this.getHistoryList();
      }
    },
    // onclose() {
    //   this.isGenealogyShow = false;
    // },
    goGenealogy() {
      this.isGenealogyShow = true;
      this.isSatisfiedShow = false;
    },
    back(status) {
      if (status === "satisfied") {
        this.isSatisfiedShow = false;
      } else {
        this.isReward = false;
        this.showCode = false;
      }
    },
    imgClick() {
      if (!this.isMobile) return;
      this.mobileImgPreview = true;
    },
    // 通过id 获取评论
    async getCommentById() {
      this.isRequestCommentById = false;
      const res = await getCommentById(this.userGPTCommentID);
      this.srcList = res.commentPictures;
      if (this.srcList.some((v) => v.discernState === 2)) {
        setTimeout(() => {
          this.getCommentById();
        }, 1000);
      } else {
        this.isRequestCommentById = true;
      }
    },
    // 预览原图
    onPreview() {
      this.imgViewerList = this.srcList.map(
        (v) =>
          `/api/fileinfo/image/GetGPTMessageCommentImageGetOriImage?filename=${v.picturePath}${v.pictureFileName}`
      );
      this.showImgViewer = true;
    },
    // 单页校对
    goToProofread(id) {
      const { href } = this.$router.resolve({
        path: "/proofread/" + id,
      });
      window.open(href, "_blank");
    },
    // 送入识别
    async sendForRecognition(id) {
      await createDiscernTask({
        userGPTCommentID: this.userGPTCommentID,
        gptMessageID: this.gptMessageID,
        forceUpdate: true,
        isVertical: false,
        picList: [id],
      });
      if (this.isRequestCommentById) {
        await this.getCommentById();
      }
    },
    previewSrcList(row) {
      this.userGPTCommentID = row.id;
      this.gptMessageID = row.gptMessageID;
      this.srcList = row.commentPictures;
      this.isPreviewImg = true;
    },
    onchange() {
      this.$forceUpdate();
    },
    //图片移除
    handleRemove(index) {
      this.fileList.splice(index, 1);
      this.$message.success("操作成功！");
    },
    handleChange(file, fileList) {
      const isImage = file.raw.type.startsWith("image/");
      if (!isImage) {
        return this.$message.error("上传的文件必须是图片!");
      }
      file.url = URL.createObjectURL(file.raw);
      this.fileList.push(file);
    },
    //文件超出个数限制时的函数
    // handleExceed(files, fileList) {
    //   this.$message.warning(
    //     `当前限制选择${this.limit} 个文件，本次选择了 ${files.length} 个文件`
    //   );
    // },
    onReceiveSearch() {
      let payload = {
        StartTime: this.ReceiveDateTimeList.length
          ? this.ReceiveDateTimeList[0]
          : null,
        EndTime: this.ReceiveDateTimeList.length
          ? this.ReceiveDateTimeList[1]
          : null,
        IsReceive: this.IsReceive,
      };
      this.getUserMsg(payload);
    },
    onSearch() {
      let payload = {
        StartTime: this.dateTimeList.length ? this.dateTimeList[0] : null,
        EndTime: this.dateTimeList.length ? this.dateTimeList[1] : null,
        IsSatisfied: this.IsSatisfied,
        IsHandled: this.IsHandled,
      };
      this.getCommentList(payload);
    },
    highlight(str, place) {
      console.log(str, place);
      if (place) {
        let tempStr = place.replace("#", "");
        let regex = new RegExp("(" + tempStr + ")", "g");
        return str.replace(regex, "<span style='color:red;'>$1</span>");
      } else {
        return str;
      }
    },
    onPopoverHide() {
      this.form.isSatisfied = 1;
      this.fileList = [];
    },
    onPopoverShow({ id }) {
      let index = this.questionList.findIndex((v) => v.id === id);
      if (this.questionList.length >= index && index != 0) {
        let str = this.questionList[index - 1].value;
        var matchResult = str.match(/姓(\S+).*来自于(\S+)/);
        // 提取匹配结果
        var lastName = matchResult[1];
        var fromPlace = matchResult[2];
        if (lastName.includes(",")) {
          this.form.userSurname = lastName.split(",")[0];
        } else if (lastName.includes("，")) {
          this.form.userSurname = lastName.split("，")[0];
        } else {
          this.form.userSurname = lastName;
        }
        if (fromPlace.includes(",")) {
          this.form.userTaiWanAncestralHome = fromPlace.split(",")[0];
        } else if (fromPlace.includes("，")) {
          this.form.userTaiWanAncestralHome = fromPlace.split("，")[0];
        } else {
          this.form.userTaiWanAncestralHome = fromPlace;
        }
      }
    },
    onload() {
      this.$nextTick(() => {
        if (this.loadingInstance) {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        }
      });
    },
    onerror() {
      this.$nextTick(() => {
        if (this.loadingInstance) {
          // 以服务的方式调用的 Loading 需要异步关闭
          this.loadingInstance.close();
        }
      });
    },
    async Before() {
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await BeforeOrNext({
          id: this.genealogyOCRInfoID,
          opType: 0,
        });
        // console.log(res, 'res')
        this.infoObjPictureFullName = res.pictureFullName;
        this.genealogyOCRInfoID = res.genealogyOCRInfoID
          .replace("；", ";")
          .split(";")[0];
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
        this.loadingInstance.close();
      }
    },
    async After() {
      this.loadingInstance = Loading.service(this.options);
      try {
        const res = await BeforeOrNext({
          id: this.genealogyOCRInfoID,
          opType: 1,
        });
        this.genealogyOCRInfoID = res.genealogyOCRInfoID
          .replace("；", ";")
          .split(";")[0];
        this.infoObjPictureFullName = res.pictureFullName;
      } catch (e) {
        if (e.response.status === "403") {
          this.$message.info("暂无数据");
        }
        this.loadingInstance.close();
      }
    },
    async readMsg({ row }) {
      try {
        const res = await readMsg(row.id);
        this.msgInfoObj = res;
        const arr = this.msgInfoObj.gptMessage.remark?.split("<br/>");

        if (arr) {
          this.msgInfoObj.gptMessage.remark = arr
            .map((v) => v.split("###")[0])
            .join("<br/>");
        }
        row.isReceive = true;
        this.readMsgShow = true;
      } catch (error) {
        console.log(error);
      }
    },
    async getUserMsg(payload) {
      try {
        const res = await getMsg({
          IsNoPage: true,
          PageIndex: 1,
          PageSize: 10,
          ...payload,
        });
        this.userMsgList = res.items;
      } catch (error) {
        console.log(error);
      }
    },
    async updateUserInfo(state) {
      try {
        if (state === "password") {
          await updatePassword(this.userForm);
          this.logout();
        } else {
          const res = await updateUser(this.userForm);
          this.$store.commit("user/SET_USER", res);
        }
        this.$message.success("操作成功！");
      } catch (error) {
        this.$message.error("操作失败！");
      } finally {
        this.userForm = {};
        this.popupShow = false;
      }
    },
    async onClick(state) {
      this.isShowUserInfo = false;
      this.closeDialog();
      this.popup = {};
      if (this.isMobile) {
        this.onAsideShow("close");
      }
      if (state === "one") {
        this.popupShow = true;
        this.popup.title = "修改基本信息";
        this.popup.isUpdateUser = true;
      } else if (state === "two") {
        this.popupShow = true;
        this.popup.title = "修改密碼";
        this.popup.isUpdatePassword = true;
      } else if (state === "three") {
        await this.getUserMsg();
        this.userMsgShow = true;
      } else if (state === "four") {
        await this.getCommentList();
        this.messageShow = true;
      } else if (state === "five") {
        await this.getJackPot();
        this.prizeShow = true;
      }
    },
    async getJackPot() {
      const res = await getJackPot({
        IsNoPage: true,
        PageSize: 10,
        PageIndex: 1,
      });
      this.paramsList = res.items;
    },
    async getCommentList(payload) {
      try {
        const res = await getCommentList({
          IsNoPage: true,
          PageSize: 10,
          PageIndex: 1,
          ...payload,
        });
        this.commentList = res.items;
      } catch (error) {
        console.log(error);
      }
    },
    changeUserInfoVisible() {
      this.isShowUserInfo = !this.isShowUserInfo;
    },
    async logout() {
      let miniprogram = null;
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          // 向小程序发送消息
          wx.miniProgram.postMessage({ data: { foo: {} } });
          wx.miniProgram.navigateBack({ delta: 2 });
          miniprogram = res.miniprogram;
        }
      });
      await this.$store.dispatch("user/logout");
      if (!miniprogram) {
        this.$router.push("/login");
      }
    },
    extractColorByName(name) {
      var str = "";
      for (var i = 0; i < name.length; i++) {
        str += parseInt(name[i].charCodeAt(0), 10).toString(16);
      }
      return "#0c" + str.slice(1, 2);
    },
    async gotoG(e) {
      if (e.target.nodeName === "SPAN") {
        if (e.target?.dataset?.sourceid) {
          const { href } = this.$router.resolve({
            path: `/tree/${e.target.dataset.sourceid}`,
            query: {
              keyword: `${e.target?.dataset?.name || ""}`,
            },
          });
          window.open(href, "_blank");
        } else if (e.target?.dataset?.ids) {
          let arr = e.target.dataset.ids.split(",");
          try {
            // this.loadingInstance = Loading.service(this.options);
            // const res = await getDetail({
            //   migrateInfoId: arr[0],
            //   migrateDetailId: arr[1],
            // });
            const res = await getDetail(arr[0]);
            this.infoObj = res;
            this.infoObjPictureFullName = res[0].pictureFullName;
            this.genealogyOCRInfoID = res[0].genealogyOCRInfoID.split(";")[0];
            this.isPicShow = true;
          } catch (error) {
            console.log(error);
          }
        } else if (e.target?.dataset?.imgname) {
          this.pictureFullName = e.target.dataset.imgname;
          this.isEbookShow = true;
        }
      }
    },

    async viewHistory(item) {
      if (
        item.answer.includes("尚无下落") ||
        item.answer.includes("老板资料给的少")
      ) {
        this.isToolShow = false;
      } else {
        this.isToolShow = true;
      }
      let addressList = [];
      // 去重提取行政区划（族地族谱使用）
      if (item.answer) {
        // this.addressList = [...item.answer.matchAll(pattern)].map((v) => ({

        const regexBrackets = /【(.*?)】/g;
        // const regexContent = /今(.*?)(?:迁|遷)入/g;
        let match;
        const results = [];

        // while ((match = regexBrackets.exec(item.answer)) !== null) {
        //   const content = match[1];
        //   const innerMatch = regexContent.exec(content);
        //   console.log(innerMatch);
        //   if (innerMatch) {
        //     results.push(innerMatch[1].trim());
        //   }
        // }

        while ((match = regexBrackets.exec(item.answer)) !== null) {
          const content = match[1];
          // 使用另一个正则表达式来匹配“今”和“遷入”之间的内容
          const regexAddress = /今(.*?)(?:迁|遷)入/g;
          let addressMatch;
          while ((addressMatch = regexAddress.exec(content)) !== null) {
            results.push(addressMatch[1].trim());
          }
        }
        // 使用 Set 去重
        // const uniqueResults = [...new Set(results)];
        let uniqueResults = [...new Set(results)];
        var regex1 = /,又/;
        uniqueResults = uniqueResults.filter((v) => {
          if (!regex1.test(v)) {
            return v;
          }
        });
        addressList = uniqueResults.map((v) => ({
          label: v,
          value: v,
        }));
        addressList.unshift({
          label: "全部",
          value: "all",
        });
      } else {
        addressList = [];
      }

      let remark = item.remark;
      this.isShowUserInfo = false;
      if (remark) {
        let arr = remark.split("<br/>");
        let newArr = [];
        if (arr.length && !this.isMobile) {
          let treeMap = {};
          for (let v of arr) {
            let tempArr = v.split("###");
            if (tempArr.length >= 2) {
              v = tempArr[0];
              v += `<br/><span
                    data-ids='${tempArr[1]}'
                    style="
                    font-size: 18px;
                      margin-top: 5px;
                       color: blue;
                      cursor: pointer;
                      margin-right: 10px;
                    "
                  >
                    查看原圖
                  </span>`;
              const ids = tempArr[1].split(",");

              if (ids.length === 3) {
                let memberTreeFinished = false;
                // 如果有重复的 id 就不请求
                if (treeMap[ids[2]] === undefined) {
                  const res = await searchTree({
                    sourceID: ids[2],
                  });
                  memberTreeFinished = res.memberTreeFinished;
                }
                treeMap[ids[2]] = memberTreeFinished;
                if (treeMap[ids[2]]) {
                  v += `<span
                    data-sourceid='${ids[2]}'
                    style="
                        font-size: 18px;
                      margin-top: 5px;
                       color: blue;
                      cursor: pointer;
                    "
                  >
                    查看世繫樹
                  </span>`;
                }
              }
            }
            newArr.push(v);
          }
          remark = newArr.join("<br/>");
        }
        if (arr.length && this.isMobile) {
          newArr = arr.map((v) => {
            let tempArr = v.split("###");
            if (tempArr.length >= 2) {
              v = tempArr[0];
              v += `<br/><span
                    data-ids='${tempArr[1]}'
                    style="
                    font-size: 18px;
                      margin-top: 5px;
                       color: blue;
                      cursor: pointer;
                      margin-right: 10px;
                    "
                  >
                    查看原圖
                  </span>`;
            }
            return v;
          });
          remark = newArr.join("<br/>");
        }
      }

      // let tempArr = [];
      // tempArr = item.answer.split("</br>");
      // tempArr = tempArr.map((v, i) => {
      //   if (v && i !== 0) {
      //     return (
      //       v +
      //       `<img data-str='${v}' data-id='${i}' style='cursor: pointer;width:22px;height:22px;vertical-align: middle' title='播放' src='${require("../../assets/播放.png")}' />`
      //     );
      //   }
      //   return v;
      // });

      this.questionList = [
        {
          id: 1,
          value: item.question,
          name: "你",
          type: "you",
        },
        {
          id: 2,
          name: "阿根",
          value: item.answer,
          // value: tempArr.join("</br>"),
          sourceValue: remark,
          msgId: item.id,
          addressList,
          show: true,
          question: item.question,
          audioSrc: "",
          audioShow: false,
        },
      ];
      this.questionIndex = 3;
      if (this.isMobile) {
        this.isShowMask = false;
        this.$refs.aside.style.left = "-500px";
      }
    },
    async getHistoryList() {
      this.getListLoading = true;
      const res = await getHistoryMes({
        PageSize: 20,
        PageIndex: this.PageIndex,
      });
      this.historyList = [...this.historyList, ...res.items];
      this.total = res.totalCount;
      if (this.total > this.historyList.length) {
        //如果还有更多重新设置flag为true
        this.flag = true;
      } else {
        //没有更多flag设置为false不在执行触底加载
        this.flag = false;
      }
      this.getListLoading = false;
    },
    async submitUpLoadThread() {
      const temLength =
        this.fileList.length < this.uploadManyCount
          ? this.fileList.length
          : this.uploadManyCount;
      const temList = this.fileList.splice(0, temLength);
      const formData = new FormData();
      temList.forEach((item, index) => {
        formData.append(`formFiles`, item.raw);
      });
      try {
        const res = await saveFiles(this.GPTMessageID, formData);

        this.fileList.splice(0, this.fileList.length);
        res.forEach((item, index) => {
          item.orderBy = index + 1;
        });

        console.log(this.form);
        await evaluate({
          ...this.form,
          isSatisfied: this.form.isSatisfied ? true : false,
          gptMessageID: this.GPTMessageID,
          commentPictures: res,
        });
        this.$message.success("评价成功！");
        this.form = {
          isSatisfied: 1,
        };
        // 关闭 el-popover
        document.body.click();
      } catch (error) {
        console.log(error);
      }
    },
    imgUpload() {
      if (this.fileList.length > 0) {
        if (this.uploadThreadCount > 0) {
          this.uploadThreadCount -= 1;
          this.submitUpLoadThread();
        }
      }
      if (this.fileList.length > 0) {
        setTimeout(() => {
          this.imgUpload();
        }, 1000);
      }
    },
    async onSubmit(id) {
      this.GPTMessageID = id;
      try {
        if (this.fileList.length) {
          this.imgUpload();
        } else {
          await evaluate({
            ...this.form,
            isSatisfied: this.form.isSatisfied ? true : false,
            gptMessageID: id,
          });
          this.$message.success("评价成功！");
          this.form = {
            isSatisfied: 1,
          };
          // 关闭 el-popover
          document.body.click();
        }
      } catch (error) {
        console.log(error);
        this.$message.error("评价失败，请稍后再试！");
      }
    },
    touchmove() {
      // this.voiceConversion("move");
    },
    touchend() {
      // this.voiceConversion("end");
      this.recStop();
    },
    touchstart() {
      this.recOpen();
    },
    recOpen() {
      //创建录音对象
      this.rec = Recorder({
        type: "mp3", //录音格式，可以换成wav等其他格式
        sampleRate: 16000, //录音的采样率，越大细节越丰富越细腻
        bitRate: 16, //录音的比特率，越大音质越好
        onProcess: (
          buffers,
          powerLevel,
          bufferDuration,
          bufferSampleRate,
          newBufferIdx,
          asyncEnd
        ) => {
          //录音实时回调，大约1秒调用12次本回调
          //可实时绘制波形，实时上传（发送）数据
          if (this.wave)
            this.wave.input(
              buffers[buffers.length - 1],
              powerLevel,
              bufferSampleRate
            );
        },
      });

      //打开录音，获得权限
      this.rec.open(
        () => {
          console.log("录音已打开");
          console.log(this.$refs.recwave);
          if (this.$refs.recwave) {
            //创建音频可视化图形绘制对象
            this.wave = Recorder.WaveView({ elem: this.$refs.recwave });
            console.log(this.wave);
            this.recStart();
          }
        },
        (msg, isUserNotAllow) => {
          //用户拒绝了录音权限，或者浏览器不支持录音
          console.log(
            (isUserNotAllow ? "UserNotAllow，" : "") + "无法录音:" + msg
          );
          this.$message.error("暂无法录音" + msg);
        }
      );
    },
    recStart() {
      if (!this.rec) {
        this.$message.error("暂无录音权限");
        return;
      }
      this.rec.start();
      console.log("已开始录音");
    },
    recStop() {
      if (!this.rec) {
        console.error("未打开录音");
        return;
      }
      this.rec.stop(
        (blob, duration) => {
          //blob就是我们要的录音文件对象，可以上传，或者本地播放
          this.recBlob = blob;
          //简单利用URL生成本地文件地址，此地址只能本地使用，比如赋值给audio.src进行播放，赋值给a.href然后a.click()进行下载（a需提供download="xxx.mp3"属性）
          var localUrl = (window.URL || webkitURL).createObjectURL(blob);
          console.log("录音成功", blob, localUrl, "时长:" + duration + "ms");
          this.upload(blob); //把blob文件上传到服务器

          this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
          this.rec = null;
        },
        (err) => {
          console.error("结束录音出错：" + err);
          this.rec.close(); //关闭录音，释放录音资源，当然可以不释放，后面可以连续调用start
          this.rec = null;
        }
      );
    },
    async upload(blob) {
      this.loadingText = "语音识别中...";
      this.loading1 = true;
      //使用FormData用multipart/form-data表单上传文件
      //或者将blob文件用FileReader转成base64纯文本编码，使用普通application/x-www-form-urlencoded表单上传
      var form = new FormData();
      form.append("audio_file", blob, "recorder.mp3"); //和普通form表单并无二致，后端接收到upfile参数的文件，文件名为recorder.mp3
      // form.append("key", "value"); //其他参数

      try {
        const res = await speechToText(
          {
            encode: true,
            task: "transcribe",
            language: "zh",
            initial_prompt: "",
            word_timestamps: false,
            output: "txt",
          },
          form
        );

        if (res) {
          const resState = await getMessageNum({
            str: res,
          });
          if (!resState.isLimit) {
            this.loading1 = false;
            return this.$message.error("您今日的提问次数已达上限！");
          }
          this.loadingText = "响应中...";
          this.isAutoScrollEnabled = true;
          this.questionList.push({
            id: this.questionIndex,
            value: res,
            name: "你",
            type: "you",
          });

          this.scrollBox.scrollTop = this.scrollBox.scrollHeight;
          this.questionIndex += 1;
          let oneEntity = {
            id: this.questionIndex,
            name: "阿根",
            title: `您是第${resState.placeNameNum}个问这问题的客郎，这是咨询阿根的第${resState.totalNum}个问题。`,
          };
          this.app
            .submit(1, [res, this.$store.state.user.token, [], null])
            .on("data", (result) => {
              this.isToolShow = true;
              var resultContent = result.data[0][0][1];
              if (this.questionList.length === this.questionIndex) {
                oneEntity = this.questionList[this.questionIndex - 1];
                if (
                  resultContent.indexOf("<br/>") > -1 &&
                  (!resultContent.includes("尚无下落") ||
                    resultContent.includes("老板资料给的少"))
                ) {
                  oneEntity.value = resultContent.substring(
                    0,
                    resultContent.indexOf("<br/>")
                  );
                  oneEntity.sourceValue = resultContent.substring(
                    resultContent.indexOf("<br/>")
                  );
                } else {
                  oneEntity.value = resultContent;
                }
                oneEntity.value = oneEntity.value.replace(
                  /(\d+)\./g,
                  "<br>$1."
                );
                this.questionList[this.questionIndex - 1] = oneEntity;
              } else {
                if (
                  resultContent.indexOf("<br/>") > -1 &&
                  (!resultContent.includes("尚无下落") ||
                    resultContent.includes("老板资料给的少"))
                ) {
                  oneEntity.value = resultContent.substring(
                    0,
                    resultContent.indexOf("<br/>")
                  );
                  oneEntity.sourceValue = resultContent.substring(
                    resultContent.indexOf("<br/>")
                  );
                } else {
                  oneEntity.value = resultContent;
                }
                this.questionList.push(oneEntity);
              }
              if (
                oneEntity.value.includes("尚无下落") ||
                oneEntity.value.includes("老板资料给的少")
              ) {
                this.isToolShow = false;
              }
              if (this.isAutoScrollEnabled) {
                this.$nextTick(() => {
                  this.scrollBox.scrollTop = this.scrollBox.scrollHeight;
                });
              }
            })
            .on("status", async (status) => {
              if (status.stage === "complete") {
                let uploadRemark = oneEntity.sourceValue;

                let newArr = [];
                if (oneEntity.sourceValue) {
                  let arr = oneEntity.sourceValue.split("<br/>");
                  if (arr.length && !this.isMobile) {
                    newArr = arr.map((v) => {
                      let tempArr = v.split("###");
                      if (tempArr.length >= 2) {
                        v = tempArr[0];
                        v += `<br/><span
                  data-ids='${tempArr[1]}'
                  style="
                    font-size: 18px;
                    margin-top: 5px;
                    color: blue;
                    cursor: pointer;
                    margin-right: 10px;
                  "
                >
                  查看原圖
                </span>`;
                      }
                      if (this.isGenealogy) {
                        // if (v.includes("耕道公")) {
                        //   v += `<span
                        //         data-name='耕道公'
                        //         style="
                        //         font-size: 18px;
                        //           margin-top: 5px;
                        //           color: blue;
                        //           cursor: pointer;
                        //           margin-right: 10px;
                        //         "
                        //       >
                        //         查看世繫樹
                        //       </span>`;
                        //   v += `<span
                        //         data-imgName='j%2Fm7KjmCPmls8%2BqcXZhOHdyGwhR6%2F1PzcYqizPcERYL6HSnl0mXsXbm3LjaVHnQjbLkcR1fQqwnbxgsssNo6fP4hZQEwHXKtWwa3olJoeNoiuZEbIbmkU9DY%2BxXHGyWv&rects=1139%2C519%2C1335%2C588&ocrSource=1'
                        //         style="
                        //         font-size: 18px;
                        //           margin-top: 5px;
                        //           color: blue;
                        //           cursor: pointer;
                        //         "
                        //       >
                        //         查看電子書
                        //       </span>`;
                        // } else if (v.includes("耕德公")) {
                        //   v += `<span
                        //         data-name='耕德公'
                        //             style="
                        //               font-size: 18px;
                        //               margin-top: 5px;
                        //               color: blue;
                        //               cursor: pointer;
                        //               margin-right: 10px;
                        //             "
                        //           >
                        //             查看世繫樹
                        //           </span>`;
                        //   v += `<span
                        //         data-imgName='j%2Fm7KjmCPmls8%2BqcXZhOHdyGwhR6%2F1PzcYqizPcERYL6HSnl0mXsXbm3LjaVHnQjbLkcR1fQqwnbxgsssNo6fP4hZQEwHXKtWwa3olJoeNoiuZEbIbmkU9DY%2BxXHGyWv&rects=1099%2C3031%2C1290%2C3099&ocrSource=1'
                        //         style="
                        //         font-size: 18px;
                        //           margin-top: 5px;
                        //           color: blue;
                        //           cursor: pointer;
                        //         "
                        //       >
                        //         查看電子書
                        //       </span>`;
                        // }
                      }
                      return v;
                    });
                    oneEntity.sourceValue = newArr.join("<br/>");
                  }

                  if (arr.length && this.isMobile) {
                    newArr = arr.map((v) => {
                      let tempArr = v.split("###");
                      if (tempArr.length >= 2) {
                        v = tempArr[0];
                        v += `<br/><span
                            data-ids='${tempArr[1]}'
                            style="
                            font-size: 18px;
                              margin-top: 5px;
                               color: blue;
                              cursor: pointer;
                              margin-right: 10px;
                            "
                          >
                            查看原圖
                          </span>`;
                      }
                      return v;
                    });
                    oneEntity.sourceValue = newArr.join("<br/>");
                  }
                }

                const obj = await newTalk({
                  question: res,
                  answer: oneEntity.value,
                  remark: uploadRemark,
                });
                // oneEntity.value = oneEntity.value
                //   .split("</br>")
                //   .map((v, i) => {
                //     if (v && i !== 0) {
                //       return (
                //         v +
                //         `<img data-str='${v}' data-id='${i}' style='cursor: pointer;width:22px;height:22px;vertical-align: middle' title='播放' src='${require("../../assets/播放.png")}' />`
                //       );
                //     }
                //     return v;
                //   })
                //   .join("</br>");
                this.questionList.map((v) => {
                  if (v.id === this.questionIndex) {
                    v.msgId = obj.id;
                  }
                });
                this.questionIndex += 1;
                this.loading1 = false;
                oneEntity.show = true;
                // this.getHistoryList();
                const { items } = await getHistoryMes({
                  PageSize: 1,
                  PageIndex: 1,
                });
                this.historyList.unshift(...items);
              } else if (status.stage === "error") {
                this.loading1 = false;
                oneEntity.show = true;
                this.questionIndex += 1;
              }
            });
        }
      } catch (error) {
        console.log(error);
        this.loading1 = false;
        this.$message.error("网络繁忙请稍后重试！");
      } finally {
      }
    },
    recPlay() {
      //本地播放录音试听，可以直接用URL把blob转换成本地播放地址，用audio进行播放
      var localUrl = URL.createObjectURL(this.recBlob);
      var audio = document.createElement("audio");
      audio.controls = true;
      document.body.appendChild(audio);
      audio.src = localUrl;
      audio.play(); //这样就能播放了

      //注意不用了时需要revokeObjectURL，否则霸占内存
      setTimeout(function () {
        URL.revokeObjectURL(audio.src);
      }, 5000);
    },
    showDetail(liId) {
      if (document.getElementById("liTab" + liId) != null) {
        if (document.getElementById("liTab" + liId).style.display === "block") {
          document.getElementById("liTab" + liId).style.display = "none";
        } else {
          document.getElementById("liTab" + liId).style.display = "block";
        }

        if (this.isAutoScrollEnabled) {
          this.$nextTick(() => {
            this.scrollContainerToBottom();
          });
        }
      }
    },
    scrollContainerToBottom() {
      // 获取滚动容器的当前滚动位置
      const startScrollTop = this.scrollBox.scrollTop;

      // 获取滚动容器滚动到底部的目标位置
      const targetScrollTop =
        this.scrollBox.scrollHeight - this.scrollBox.clientHeight;

      // 计算滚动的距离和步数
      const distance = targetScrollTop - startScrollTop;
      const duration = 500; // 滚动持续时间
      const startTime = performance.now();

      // 定义滚动函数
      let that = this;

      function scrollAnimation(currentTime) {
        const elapsedTime = currentTime - startTime;
        const percentage = Math.min(elapsedTime / duration, 1);
        that.scrollBox.scrollTop = startScrollTop + distance * percentage;

        if (percentage < 1) {
          requestAnimationFrame(scrollAnimation);
        }
      }

      // 启动滚动动画
      requestAnimationFrame(scrollAnimation);
    },
    async init() {
      const address = await getAddress(`${window.location.origin}/address.js`);
      // this.audioApp = await client("http://192.168.1.34:2200/");
      this.app = await client(address, {
        space_status: (space_status) => console.log(space_status),
      });
    },
    async handleSend() {
      if (!this.loading) {
        this.txtSurname = this.txtSurname?.trim();
        this.txtFromArea = this.txtFromArea?.trim();
        this.txtFromName = this.txtFromName?.trim();

        if (!this.txtSurname) {
          return this.$message.info("请输入姓氏");
        }
        if (!this.txtFromArea) {
          return this.$message.info("请输入臺灣祖地");
        }
        //判断如果长度大于1 判断是否是复姓,是就取前两个字,不是就取前一个字
        if (this.txtSurname.length > 1) {
          this.compoundSurname.includes(this.txtSurname.slice(0, 2))
            ? (this.txtSurname = this.txtSurname.slice(0, 2))
            : (this.txtSurname = this.txtSurname.slice(0, 1));
        }
        // 判断是否能够问答
        const res = await getMessageNum({
          surname: this.txtSurname,
          placeName: this.txtFromArea,
          ancestorName: this.txtFromName || "",
          str: "",
        });
        if (!res.isLimit)
          return this.$message.error("您今日的提问次数已达上限！");
        this.isAutoScrollEnabled = true;
        this.isGenealogy = false;

        if (
          this.txtSurname != "" ||
          this.txtFromArea != "" ||
          this.txtFromName != ""
        ) {
          let searInfo = `我姓${this.txtSurname},来自于${this.txtFromArea}${
            this.txtFromName ? ",祖先是" + this.txtFromName : ""
          }`;
          this.txtSurname = "";
          this.txtFromArea = "";
          this.txtFromName = "";
          this.questionList.push({
            id: this.questionIndex,
            value: searInfo,
            name: "你",
            type: "you",
          });

          this.scrollBox.scrollTop = this.scrollBox.scrollHeight;

          this.questionIndex += 1;
          this.loading = true;
          let oneEntity = {
            id: this.questionIndex,
            name: "阿根",
            question: searInfo,
            title: `您是第${res.placeNameNum}個問這問題的客郎，這是咨詢阿根的第${res.totalNum}個問題。<br />`,
            audioSrc: "",
            audioShow: false,
          };

          // if (this.questionList.length === this.questionIndex) {
          //   oneEntity = this.questionList[this.questionIndex - 1];
          //   this.questionList[this.questionIndex - 1] = oneEntity;
          // } else {
          //   this.questionList.push(oneEntity);
          // }

          if (this.isAutoScrollEnabled) {
            this.$nextTick(() => {
              this.scrollContainerToBottom();
            });
          }
          this.app
            .submit(0, [searInfo, this.$store.state.user.token, [], null])
            .on("data", (result) => {
              this.isToolShow = true;
              var resultContent = result.data[0][0][1];
              if (this.isAutoScrollEnabled) {
                this.scrollBox.scrollTop = this.scrollBox.scrollHeight;
              }
              if (this.questionList.length == this.questionIndex) {
                oneEntity = this.questionList[this.questionIndex - 1];
                if (
                  resultContent.indexOf("<br/>") > -1 &&
                  (!resultContent.includes("尚无下落") ||
                    resultContent.includes("老板资料给的少"))
                ) {
                  oneEntity.value = resultContent.substring(
                    0,
                    resultContent.indexOf("<br/>")
                  );
                  oneEntity.sourceValue = resultContent.substring(
                    resultContent.indexOf("<br/>")
                  );
                } else {
                  oneEntity.value = resultContent;
                }
                oneEntity.value = oneEntity.value
                  .replace(/(\d+)\./g, "</br>$1.")
                  .replace(/<\/br>(<\/br>)/g, "</br>");

                this.questionList[this.questionIndex - 1] = oneEntity;
              } else {
                if (
                  resultContent.indexOf("<br/>") > -1 &&
                  (!resultContent.includes("尚无下落") ||
                    resultContent.includes("老板资料给的少"))
                ) {
                  oneEntity.value = resultContent.substring(
                    0,
                    resultContent.indexOf("<br/>")
                  );
                  oneEntity.sourceValue = resultContent.substring(
                    resultContent.indexOf("<br/>")
                  );
                } else {
                  oneEntity.value = resultContent;
                }
                this.questionList.push(oneEntity);
              }
              if (
                oneEntity.value.includes("尚无下落") ||
                oneEntity.value.includes("老板资料给的少")
              ) {
                this.isToolShow = false;
              }
            })
            .on("status", async (status) => {
              if (status.stage === "complete") {
                let uploadRemark = oneEntity.sourceValue;
                this.loading = false;
                let newArr = [];
                if (oneEntity.sourceValue) {
                  let arr = oneEntity.sourceValue.split("<br/>");
                  if (arr.length && !this.isMobile) {
                    let treeMap = {};
                    for (let v of arr) {
                      let tempArr = v.split("###");
                      if (tempArr.length >= 2) {
                        v = tempArr[0];
                        v += `<br/><span
                          data-ids='${tempArr[1]}'
                          style="
                          font-size: 18px;
                            margin-top: 5px;
                            color: blue;
                            cursor: pointer;
                            margin-right: 10px;
                          "
                        >
                          查看原圖
                        </span>`;
                        const ids = tempArr[1].split(",");
                        if (ids.length === 3) {
                          let memberTreeFinished = false;
                          // 如果有重复的 id 就不请求
                          if (treeMap[ids[2]] === undefined) {
                            const res = await searchTree({
                              sourceID: ids[2],
                            });
                            memberTreeFinished = res.memberTreeFinished;
                          }
                          treeMap[ids[2]] = memberTreeFinished;
                          if (treeMap[ids[2]]) {
                            v += `<span
                              data-sourceid='${ids[2]}'
                              style="
                                font-size: 18px;
                                margin-top: 5px;
                                color: blue;
                                cursor: pointer;
                              "
                            >
                              查看世繫樹
                            </span>`;
                          }
                        }
                      }

                      newArr.push(v);
                    }
                    oneEntity.sourceValue = newArr.join("<br/>");
                  }

                  if (arr.length && this.isMobile) {
                    newArr = arr.map((v) => {
                      let tempArr = v.split("###");
                      if (tempArr.length >= 2) {
                        v = tempArr[0];
                        v += `<br/><span
                            data-ids='${tempArr[1]}'
                            style="
                                font-size: 18px;
                              margin-top: 5px;
                               color: blue;
                              cursor: pointer;
                              margin-right: 10px;
                            "
                          >
                            查看原圖
                          </span>`;
                      }
                      return v;
                    });
                    oneEntity.sourceValue = newArr.join("<br/>");
                  }
                }

                const res = await newTalk({
                  question: searInfo,
                  answer: oneEntity.value,
                  remark: uploadRemark,
                });
                // oneEntity.value = oneEntity.value
                //   .split("</br>")
                //   .map((v, i) => {
                //     if (v && i !== 0) {
                //       return (
                //         v +
                //         `<img data-str='${v}' data-id='${i}' style='cursor: pointer;width:22px;height:22px;vertical-align: middle' title='播放' src='${require("../../assets/播放.png")}' />`
                //       );
                //     }
                //     return v;
                //   })
                //   .join("</br>");

                if (res.answer) {
                  oneEntity.addressList = [];
                  const regexBrackets = /【(.*?)】/g;
                  // const regexContent = /今(.*?)(?:迁|遷)入/g;
                  let match;
                  const results = [];

                  // while ((match = regexBrackets.exec(res.answer)) !== null) {
                  //   const content = match[1];
                  //   const innerMatch = regexContent.exec(content);
                  //   if (innerMatch) {
                  //     results.push(innerMatch[1].trim());
                  //   }
                  // }

                  while ((match = regexBrackets.exec(res.answer)) !== null) {
                    const content = match[1];
                    // 使用另一个正则表达式来匹配“今”和“遷入”之间的内容
                    const regexAddress = /今(.*?)(?:迁|遷)入/g;
                    let addressMatch;
                    while (
                      (addressMatch = regexAddress.exec(content)) !== null
                    ) {
                      results.push(addressMatch[1].trim());
                    }
                  }

                  // 使用 Set 去重
                  // const uniqueResults = [...new Set(results)];
                  let uniqueResults = [...new Set(results)];
                  var regex1 = /,又/;
                  uniqueResults = uniqueResults.filter((v) => {
                    if (!regex1.test(v)) {
                      return v;
                    }
                  });

                  oneEntity.addressList = uniqueResults.map((v) => ({
                    label: v,
                    value: v,
                  }));
                  oneEntity.addressList.unshift({
                    label: "全部",
                    value: "all",
                  });
                } else {
                  oneEntity.addressList = [];
                }

                if (res.isJackpot) {
                  this.num = res.jackpotNum;
                  this.isReward = true;
                }

                this.questionList.map((v) => {
                  if (v.id === this.questionIndex) {
                    v.msgId = res.id;
                  }
                });
                this.questionIndex += 1;
                oneEntity.show = true;

                const { items } = await getHistoryMes({
                  PageSize: 1,
                  PageIndex: 1,
                });
                this.historyList.unshift(...items);
              } else if (status.stage === "error") {
                this.loading = false;
                oneEntity.show = true;
                this.questionIndex += 1;
              }
            });
        }
      }
    },
    async convertToSpeech(text) {
      const result = await this.audioApp.predict(2, [
        text,
        0.3,
        0.7,
        20,
        2,
        42,
        true,
      ]);
      return result.data[0].data;
      // obj.audioSrc = result.data[0].data;
    },
    handleWheel(event) {
      if (
        this.$refs.scrollBox.scrollHeight - this.$refs.scrollBox.scrollTop ===
        this.$refs.scrollBox.clientHeight
      ) {
        this.isAutoScrollEnabled = true;
      } else {
        // 极端情况判断
        if (this.$refs.scrollBox.scrollTop < this.oldScrollTop) {
          // 用户滚动鼠标滚轮时停止实时滚动
          this.isAutoScrollEnabled = false;
        } else {
          this.isAutoScrollEnabled = true;
        }
      }
      this.oldScrollTop = this.$refs.scrollBox.scrollTop || 0;
    },
    addNewTalk() {
      this.questionList = [];
      if (this.isMobile) {
        this.isShowMask = false;
        this.$refs.aside.style.left = "-500px";
      }
      this.questionIndex = 1;
    },
    onAsideShow(state) {
      if (state === "close") {
        this.isShowMask = false;
        this.$refs.aside.style.left = "-500px";
      } else {
        this.isShowMask = true;
        this.$refs.aside.style.left = "0px";
      }
    },
    isMobileFn() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      this.isMobile = Boolean(flag);
    },
  },
};
</script>

<style lang="scss" scoped>
.upload {
  margin-top: 10px;
  height: 130px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 10px;
    width: 80px;
    height: 80px;
    border-radius: 4px;
  }

  .u-li {
    padding: 2px;
    // background: #ddcebc;
    position: relative;

    .el-image {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }

    .upload-list-icon {
      display: none;
      box-sizing: border-box;
      padding: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      color: #fff;
      font-size: 20px;
      text-align: center;

      span {
        margin: 0 15px;
        cursor: pointer;
      }
    }
  }

  .u-li:hover .upload-list-icon {
    display: block;
  }

  .u-li:hover {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid #715125;
  }

  ::v-deep .el-upload--picture-card {
    width: 80px;
    height: 80px;
    line-height: 80px;
    border: 1px solid #ddcebc;
    // background: #ddcebc;
  }

  ::v-deep .el-upload--picture-card:hover {
    border-color: #715125;
  }

  ::v-deep .el-upload--picture-card i {
    color: #715125;
  }
}

.litab {
  border: 1px solid #24a3f8;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
  color: #000;
  background: #fff;
}

.class1,
.class2 {
  padding: 10px;
  border-radius: 10px;
  font-size: 18px;
  background: #fff;
  font-weight: 500;
  position: relative;
}

.class1::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #fff;
  top: 15px;
  left: -20px;
}

.class2::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-left-color: #fff;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
}

::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 96px;
  height: 96px;
}

::v-deep .el-upload--picture-card {
  width: 96px;
  height: 96px;
  line-height: 96px;
}

.btn:hover {
  background-color: #3f9eff;
  color: #fff;
  border-color: #3f9eff;
}

.btn {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}

.mask {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8;
  background: #000;
  opacity: 0.5;
}

.test {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
}

::v-deep .el-table .warning-row {
  background: oldlace;
}

::v-deep .el-table .success-row {
  background: #f0f9eb;
}

.back {
  background-image: url("../../assets/robot/bj.jpg") !important;
  background-size: 100% !important;
}

.chat-box {
  position: relative;
  width: 100%;
  height: 100vh;
  font-family: "宋体";

  #dlog {
    ::v-deep .el-dialog__header {
      background: #fff;
      color: #1d1d1d;
      padding: 20px 20px 10px;
      height: 54px;
      border-bottom: 1px solid #333d4e;

      .el-dialog__close,
      .el-dialog__title {
        color: #1d1d1d;
        font-size: 24px;
      }
    }

    .dialog_box {
      display: flex;
      height: 60vh;

      .left {
        height: 60vh;
        display: flex;
        overflow: hidden;
        justify-content: center;
        flex: 1;
        border-right: 1px solid #eee;
      }

      .right {
        padding: 0 20px;
        width: 30%;
        height: 100%;
        overflow: auto;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .popup {
    ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: #f2f2f2;
    }

    ::-webkit-scrollbar-thumb {
      background: #9cc3ce;
      border-radius: 10px;
    }

    /* 其他浏览器 */
    * {
      scrollbar-width: auto;
      scrollbar-color: #9cc3ce #f2f2f2;
    }

    *::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    *::-webkit-scrollbar-thumb {
      background: #005baa;
      border-radius: 10px;
    }

    *::-webkit-scrollbar-track {
      background-color: #002b4f;
    }

    *::-webkit-scrollbar-thumb:hover {
      background-color: #007fff;
    }

    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px;
      margin-bottom: 10px;
      box-sizing: border-box;
      border-bottom: 1px solid #333d4e;
    }
  }

  .read {
    height: 300px;
    overflow: auto;
    padding: 0 20px 20px !important;

    p {
      line-height: 1.75;
      text-align: start;
    }

    .br {
      margin-bottom: 10px;
      border-bottom: 1px solid #000;
    }
  }

  .upload-img {
    ul {
      display: flex;

      li {
        margin: 5px;
        position: relative;
        overflow: hidden;

        .triangle {
          width: 135px;
          height: 0;
          border-top: 28px solid;
          position: absolute;
          left: -45px;
          top: -9px;
          transform: rotate(120deg);
          color: #9daffa;
          z-index: 9;

          span {
            color: #fff;
            position: absolute;
            transform: rotate(180deg);
            margin-top: -22px;
            text-align: right;
            font-size: 12px;
            letter-spacing: 1px;
          }
        }

        .icon {
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          cursor: default;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          opacity: 0;
          font-size: 20px;
          background-color: rgba(0, 0, 0, 0.5);
          transition: opacity 0.3s;
          line-height: 1.8;
          vertical-align: middle;

          i {
            cursor: pointer;
            margin: 0 5px;
          }
        }

        .icon:hover {
          opacity: 1;
        }
      }
    }
  }

  .popup-content {
    /* 添加内容样式 */
    padding: 0 20px 20px;

    p {
      border-bottom: 1px solid #eee;
      padding: 10px;
    }

    // display: flex;

    // .my-avatar {
    //   width: 50px;
    //   height: 50px;
    // }
    // .name-time {
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: start;
    //   align-items: start;
    //   .time {
    //     font-size: 14px;
    //   }
    //   .name {
    //     font-size: 20px;
    //   }
    // }
  }

  .header {
    height: 65px;
    background: #3b3b3b;
    display: flex;
    color: #fff;
    background: url("../../assets/banner.png");
    background-size: 100% 100%;

    .nav {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        cursor: pointer;
        display: flex;
        align-items: center;

        .img {
          color: #fff;
          height: 50px;
          margin-right: 10px;
        }
      }
    }
  }

  .box {
    height: calc(100vh - 65px);
    // height: 100vh;
    display: flex;

    .aside {
      min-width: 15%;
      max-width: 15%;
      height: 100%;
      background: #f9f9f9;
      padding-bottom: 50px;
      padding-top: 0;
      position: relative;
      box-sizing: border-box;

      //.code {
      //  position: absolute;
      //  right: 50%;
      //  transform: translateX(50%);
      //  bottom: 50px;
      //}

      h4 {
        padding: 8px 15px;
        color: #0d0d0d;
        border-radius: 5px;
        font-weight: 500;
        font-size: 22px;
        cursor: pointer;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;

        span {
          margin-left: 10px;
        }
      }

      // h4:hover {
      //   background: #b3c2d9;
      //   color: #000;
      // }

      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f2f2f2;
      }

      ::-webkit-scrollbar-thumb {
        background: #9cc3ce;
        border-radius: 10px;
      }

      /* 其他浏览器 */
      * {
        scrollbar-width: auto;
        scrollbar-color: #9cc3ce #f2f2f2;
      }

      *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      *::-webkit-scrollbar-thumb {
        background: #9cc3ce;
        border-radius: 10px;
      }

      *::-webkit-scrollbar-track {
        background-color: #f2f2f2;
      }

      ul {
        padding: 0;
        overflow: auto;
        // height: calc(100% - 70px);
        // height: calc(100% - 515px);
        height: calc(100% - 300px);

        li {
          padding: 15px 15px;
          border-radius: 5px;
          font-size: 18px;
          width: 100%;
          cursor: pointer;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        li:hover {
          background: #b3c2d9;
          color: #000;
        }
      }

      .user {
        position: absolute;
        width: 100%;
        height: 50px;
        bottom: 0;
        left: 0;
        padding-right: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        border-radius: 5px;

        .avatar {
          width: 100%;
          display: flex;
          align-items: center;
        }

        .info {
          position: absolute;
          background: #fff;
          width: 98%;
          border-radius: 5px;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
            rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
          top: 0;
          left: 50%;
          transform: translate(-50%, -103%);
          padding: 5px;

          ul {
            margin: 0;

            li {
              padding: 15px 10px;
              display: flex;
              align-items: center;

              i {
                font-style: normal;
                font-size: 16px;
              }
            }

            .icon {
              font-size: 20px;
              color: #000;
              margin-right: 10px;
            }

            i {
              font-style: normal;
              font-size: 16px;
            }
          }

          hr {
            margin: 10px 0;
          }

          .logout {
            padding: 15px 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;

            .icon {
              font-size: 20px;
              color: #000;
              margin-right: 10px;
            }

            i {
              font-style: normal;
              font-size: 16px;
            }
          }

          .logout:hover {
            background: #ececec;
          }
        }

        .img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }

        span {
          font-size: 16px;
          font-weight: 600;
          margin-left: 10px;
        }
      }

      .user:hover {
        background: #ececec;
      }

      .user1 {
        background: #4a9bec;
        .info {
          background: #b3c2d9;
          color: #000;

          li:hover {
            background: #ececec;
          }
        }
      }

      .user1:hover {
        background: #b3c2d9;
      }
    }

    .content {
      height: 100%;
      flex: 1;
      background: #ffffff;
      position: relative;

      .gs {
        position: absolute;
        height: 30px;
        color: #fff;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;
        width: 100%;
        text-align: center;
        font-size: 18px;
        line-height: 30px;

        span {
          cursor: pointer;
        }
      }

      .title {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
        font-size: 20px;
        padding-top: 10px;
        font-weight: 500;
        padding-left: 10px;
        // border-bottom: 1px solid #eee;
        // background: #d1e9fd;

        .input1 {
          // position: absolute;
          height: 65px;
          // bottom: 0px;
          // left: 50%;
          // transform: translateX(-50%);
          // background-image: linear-gradient(to right, #001f5b, #0057ac);
          padding: 10px 15px;
          border-radius: 10px;
          display: flex;
          align-items: center;

          ::v-deep .el-input__inner {
            width: 250px;
            height: 50px;
            border-radius: 8px;
            margin-right: 8px;
            background: #ffffff;
            padding-left: 90px;
            border: 1px solid #005fb2;
          }

          ::v-deep .area1 {
            .el-input__inner {
              width: 280px;
              padding-left: 130px;
            }
          }

          ::v-deep .name1 {
            .el-input__inner {
              width: 280px;
              padding-left: 130px;
            }
          }

          ::v-deep .el-input__prefix {
            line-height: 50px;
          }

          ::v-deep .el-button {
            height: 50px;
            border: 1px solid #005fb2;
            background: #b3d5ed;
          }

          .icon {
            color: #000;
          }

          .name {
            width: 250px;
            height: 65px;
            padding: 5px 10px;
            border-radius: 8px;
            margin-right: 8px;
            border: 1px solid rgba(0, 0, 0, 0.15);

            p {
              margin: 0;
            }
          }

          .area {
            width: 250px;
            height: 65px;
            padding: 5px 10px;
            border-radius: 5px;
            border: 1px solid rgba(0, 0, 0, 0.15);
            margin-right: 8px;

            p {
              margin: 0;
            }
          }
        }
      }

      .dialogue {
        margin: 20px auto 0;
        overflow: auto;
        width: 100%;
        height: calc(100% - 120px);
        padding: 0 20px;
        position: relative;

        .popoverClass {
          height: 300px;
          overflow: auto;
        }

        .help {
          margin: 0 auto;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 26px;
          font-weight: 500;

          .a {
            margin: 0 auto;
            width: 1200px;
            height: 700px;
            padding: 100px 190px;
            padding-top: 140px;
            // width: 800px;
            // height: 508px;
            background: url("../../assets/robot/背景框1.png") no-repeat;
            background-size: 100% 100%;
            // padding: 0 70px;
            // padding-top: 52px;

            p {
              font-size: 18px;
              font-family: "宋体";

              em {
                font-style: normal;
                color: #35bcf8;
              }
            }
          }

          p {
            margin-top: 10px;
            text-indent: 2em;
          }
        }

        li {
          display: flex;
          margin: 20px 0;
          align-items: flex-start;

          .audio-box {
            position: relative;
            border-radius: 10px;
            --tw-bg-opacity: 1;
            margin-top: 10px;

            height: 60px;
            display: flex;
            align-items: center;
            background-color: rgb(255 255 255 / var(--tw-bg-opacity));

            audio {
              display: block;
              width: 100%;
              height: 42px;
              margin: 0 10px;
            }
          }
        }

        .rowReverse {
          flex-direction: row-reverse;
        }

        .user-ai-box {
          display: flex;
          align-items: center;
          white-space: nowrap;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }

          span {
            font-size: 20px;
            font-weight: 700;
            margin: 0 10px;
            vertical-align: middle;
          }
        }

        p {
          line-height: 1.75;
          font-size: 18px;
        }
      }

      .input {
        position: absolute;
        height: 65px;
        bottom: 35px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;

        .name {
          width: 370px;
          height: 65px;
          padding: 5px 10px;
          border-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          margin-right: 8px;

          p {
            margin: 0;
          }
        }

        .area {
          width: 370px;
          height: 65px;
          padding: 5px 10px;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.15);
          margin-right: 8px;

          p {
            margin: 0;
          }
        }
      }

      .info {
        font-size: 14px;
        position: absolute;
        bottom: 5px;
        left: 50%;
        color: #676767;
        transform: translateX(-50%);
      }
    }
  }
}

.dialog_box_mobile {
  //height: 100vh;
  //width: 100vw;
  //position: relative;

  .left {
    height: 60vh;
    display: flex;
    overflow: hidden;
    justify-content: center;
    flex: 1;
    border-right: 1px solid #eee;
  }

  .right {
    height: 20vh;
    overflow: auto;
    margin-top: 10px;
    padding: 0 5px;
  }

  .el-dialog__footer {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

#dlog1 {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
}

::v-deep #dlog1 {
  ::v-deep .el-dialog {
    margin-top: 0 !important;
  }
}

.input,
.input1 {
  ::v-deep .el-input__inner {
    border: 0.5px solid rgba(255, 255, 255, 0.8); //修改4个边框颜色
    border-radius: 0px; //取消圆角
    opacity: 0.7;
    height: 30px;
    padding: 0;
    border-bottom-color: transparent; //修改底部边框颜色
  }

  ::v-deep input::-webkit-input-placeholder {
    color: #333d4e;
    opacity: 0.8;
    font-size: 14px !important;
  }

  //Firefox版本4-18

  ::v-deep input:-moz-placeholder {
    color: #333d4e;
  }

  //Firefox版本19+

  ::v-deep input::-moz-placeholder {
    color: #333d4e;
  }

  //IE浏览器

  ::v-deep input:-ms-input-placeholder {
    color: #333d4e;
  }
}

@media (max-width: 840px) {
  ol,
  ul {
    padding-left: 0 !important;
  }
  .chat-box {
    /* 调整聊天框在小屏幕上的布局 */
    width: 100%;
    overflow: hidden;

    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }

    .popup {
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f2f2f2;
      }

      ::-webkit-scrollbar-thumb {
        background: #9cc3ce;
        border-radius: 10px;
      }

      /* 其他浏览器 */
      * {
        scrollbar-width: auto;
        scrollbar-color: #9cc3ce #f2f2f2;
      }

      *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      *::-webkit-scrollbar-thumb {
        background: #9cc3ce;
        border-radius: 10px;
      }

      *::-webkit-scrollbar-track {
        background-color: #f2f2f2;
      }

      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 95% !important;
      max-height: 70%;
      overflow: hidden;

      .popup-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        margin-bottom: 10px;
        box-sizing: border-box;
        border-bottom: 1px solid #333d4e;
      }
    }

    .popup-content {
      /* 添加内容样式 */
      padding: 0 20px 20px !important;
    }

    .read {
      height: 150px !important;
      overflow: auto;
      padding: 0 20px 20px !important;

      p {
        line-height: 1.75;
        text-align: start;
      }

      .br {
        margin-bottom: 10px;
        border-bottom: 1px solid #000;
      }
    }

    .box {
      height: 100vh;

      .aside {
        position: fixed;
        left: -500px;
        z-index: 9;
        max-width: 65% !important;
        min-width: 65% !important;
        transition: left 0.3s;

        .user {
          background: #eee;
        }
        ul {
          height: calc(100% - 70px);
        }

        ul > li {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .content {
      .title-box {
        height: 50px;
        line-height: 50px;
        font-size: 20px;
        font-weight: 500;
        padding: 0 10px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #135291;
        color: #fff;

        .temp {
          width: 20px;
          height: 20px;
        }
      }
    }

    .dialogue {
      // max-width: 350px !important;
      margin: 0 auto !important;
      height: calc(100% - 250px) !important;
      padding: 0 !important;

      .help {
        // width: 350px !important;
        padding: 0 5px;
        display: block !important;

        // p {
        //   margin-top: 0 !important;
        // }
      }

      p {
        padding: 0 !important;
      }

      li {
        padding: 5px 5px !important;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin: 0 !important;

        .user-ai-box {
          margin: 10px 0;

          span {
            margin: 0 !important;
          }
        }

        p {
          line-height: 1.5;
          font-size: 18px;
        }
      }

      .rowReverse {
        width: 100%;
        display: flex;
        flex-direction: column !important;
        align-items: flex-end !important;
      }
    }

    .input {
      flex-direction: column;
      height: auto !important;
      bottom: 10px !important;
      width: 98%;

      ::v-deep .el-input__inner {
        /* 适应小屏幕的输入框样式调整 */
        height: 25px;
        font-size: 12px;
        // background: #6489ae;
        border: none;
        color: #000;
      }

      ::v-deep .el-input__inner::placeholder {
        color: #000;
      }

      ::v-deep .van-button {
        border-color: #6489ae;
        border-radius: 5px;
      }

      .name {
        height: 60px !important;
        width: 100% !important;
        margin: 0 auto !important;
        border-color: #6489ae !important;
      }

      .area-box {
        display: flex;
      }

      .area {
        margin: 5px auto !important;
        border-color: #6489ae !important;
      }
    }
  }

  /* 其他针对移动端的样式调整 */
}
</style>
